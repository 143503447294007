import {
	Component,
	Inject,
	LOCALE_ID,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadSelectableOptions } from './state/layout.actions';
import { take } from 'rxjs/operators';
import { ILayoutState } from '@layout/state/layout.reducer';
import { AuProfileService } from '@app/core/services/au-profile.service';
import ISettings from '@shared/interfaces/settings.interface';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import {
	MatDrawer,
	MatDrawerContainer,
	MatDrawerContent,
} from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import {
	getUnreadNotificationsCount,
	loadNotifications,
} from '@core/notifications/state/notifications.actions';
import { AuBreadcrumbsService } from '@app/core/services/au-breadcrumbs.service';
import { FileLoaderService } from '@app/shared/ui-components/au-file-loader/services/file-loader.service';
import { AuRightSidebarComponent } from './au-right-sidebar/au-right-sidebar.component';
import { AuNavSidebarComponent } from './au-nav-sidebar/au-nav-sidebar.component';
import { HeaderComponent } from './header/header.component';
import { RouterOutlet } from '@angular/router';
import { RightSidebarContainerComponent } from './new-right-sidebar/components/right-sidebar-container/right-sidebar-container.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { PrivacyPolicyComponent } from '../core/privacy-policy/privacy-policy.component';
import { VersionCheckComponent } from '../core/version-check/version-check.component';

@Component({
	selector: 'app-main-layout',
	templateUrl: './application-layout.component.html',
	styleUrls: ['./application-layout.component.scss'],
	imports: [
		MatDrawerContainer,
		MatDrawer,
		AuRightSidebarComponent,
		MatDrawerContent,
		AuNavSidebarComponent,
		HeaderComponent,
		RouterOutlet,
		RightSidebarContainerComponent,
		NgIf,
		MatFabButton,
		MatIcon,
		PrivacyPolicyComponent,
		VersionCheckComponent,
		AsyncPipe,
	],
})
export class ApplicationLayoutComponent implements OnInit {
	locales = [];
	settings: ISettings;
	showPolicyBar;
	navSidebarCollapsed$: Observable<boolean>;

	@ViewChild('rightSidebar', { static: true })
	private rightSidebar: MatDrawer;
	@ViewChild('mainContent', { static: true, read: ViewContainerRef })
	private mainContent: ViewContainerRef;

	constructor(
		@Inject(LOCALE_ID) public locale: string,
		private store$: Store<ILayoutState>,
		private profileService: AuProfileService,
		public sidebarService: AuRightSidebarService,
		public breadcrumbsService: AuBreadcrumbsService,
		private fileLoaderService: FileLoaderService
	) {}

	ngOnInit() {
		this.sidebarService.sidebar = this.rightSidebar;
		this.fileLoaderService.containerRef = this.mainContent;

		// TODO refactor this to dispatch only 1 action like layoutOpened() and handle necessary actions in the effect
		this.store$.dispatch(LoadSelectableOptions());
		this.store$.dispatch(loadNotifications());
		this.store$.dispatch(getUnreadNotificationsCount());

		this.profileService.userProfile$.pipe(take(1)).subscribe(profile => {
			this.showPolicyBar = !profile?.policyAccepted;
		});
	}

	privacyPolicyAccepted(): void {
		this.showPolicyBar = false;
		this.profileService.policyAccepted().subscribe(res => {
			if (res.success) {
				this.profileService.setUserProfile(res.profile);
			}
		});
	}

	undoMinimize() {
		this.sidebarService.undoMinimize();
	}
}
