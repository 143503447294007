import {
	ChangeDetectionStrategy,
	Component,
	inject,
	input,
	OnChanges,
	signal,
	WritableSignal,
} from '@angular/core';
import { OptionsButtonComponent } from './options-button/options-button.component';
import { EntityFormConfig } from '../types';
import { IWorkOrder } from '@app/pages/cmms/interfaces/work-order.interface';
import { IIncident } from '@app/pages/cmms/interfaces/incidents.interface';
import { IAttachFiles } from '@app/shared/ui-components/au-file-loader/au-file-loader.interfaces';
import { ACTIVE_PAGE_CONFIG } from '@app/pages/cmms/configs/active-page.config';
import { SetRightSidebarComponentContext } from '@app/layout/state/layout.actions';
import { EmailService } from '@app/pages/settings/email-service/email-service.service';
import { CmmsSharedService } from '@app/pages/cmms/services/cmms-shared.service';
import { RightSidebarNavigationService } from '@app/layout/au-right-sidebar/right-sidebar-navigation.service';
import { CmmsPages, ICmmsState } from '@app/pages/cmms/state/cmms.reducer';
import { Store } from '@ngrx/store';
import { addWorkOrderExternalData } from '@app/pages/cmms/components/work-order/state/work-order.actions';
import { NewRightSidebarService } from '@app/layout/new-right-sidebar/services/new-right-sidebar/new-right-sidebar.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateService } from '@tolgee/ngx';
@Component({
	selector: 'entity-workspace-actions',
	imports: [OptionsButtonComponent, MatIconModule, CommonModule, MatButtonModule],
	templateUrl: './entity-workspace-actions.component.html',
	styleUrl: './entity-workspace-actions.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityWorkspaceActionsComponent implements OnChanges {
	private readonly sidebar = inject(NewRightSidebarService);
	private readonly emailService = inject(EmailService);
	private readonly cmmsSharedService = inject(CmmsSharedService);
	// TODO: Replace when we have a different page for sharing
	private readonly sidebarNavigation = inject(RightSidebarNavigationService);
	private readonly store$ = inject(Store<ICmmsState>);
	private readonly translatedService = inject(TranslateService);
	config = input<EntityFormConfig>();
	locationAncestry = input([]);
	shareable: WritableSignal<boolean> = signal(false);
	canMakeCopy: WritableSignal<boolean> = signal(false);

	ngOnChanges(): void {
		switch (this.config().entityType) {
			case 'WO':
				this.shareable.set(true);
				this.canMakeCopy.set(true);
		}
	}

	share() {
		// TODO: Will delete unsaved changes, add warning for users
		if (!this.shareable()) {
			return;
		}

		const config = this.config();
		let cmmsEntity: IWorkOrder | IIncident;
		let entityId: number;
		let files: IAttachFiles[];
		let entityName: string;
		switch (config.entityType) {
			case 'WO':
				cmmsEntity = config.data;
				entityId = config.data.workOrderId;
				files = config.data.files.filter(file => file.importConfirmed || !file.importId);
				entityName = this.translatedService.instant('work_order');
				break;
		}
		this.emailService.setMessageExternalData(
			cmmsEntity,
			`${entityName} ${entityId}`,
			this.locationAncestry(),
			files
		);
		this.cmmsSharedService.setExternalDataForNewComponent(CmmsPages.messages, cmmsEntity);
		this.sidebarNavigation.navigateTo(
			CmmsPages.messages,
			ACTIVE_PAGE_CONFIG[CmmsPages.messages].sidebarConfig
		);
		this.sidebar.close();
	}

	copyEntity() {
		const config = this.config();
		let navigationCmmsPage: CmmsPages;
		let configCmmsPage: CmmsPages;
		this.store$.dispatch(
			SetRightSidebarComponentContext({
				componentContext: {
					actionType: 'COPY',
				},
			})
		);

		switch (config.entityType) {
			case 'WO':
				navigationCmmsPage = CmmsPages.newWorkOrder;
				configCmmsPage = CmmsPages.wo;
				this.store$.dispatch(
					addWorkOrderExternalData({ ...structuredClone(config.data) })
				);
				break;
		}

		this.sidebar.close();

		this.sidebarNavigation.navigateTo(navigationCmmsPage, {
			...ACTIVE_PAGE_CONFIG[configCmmsPage].sidebarConfig,
			title: {
				text: this.translatedService.instant('copy_of') + config.data?.name,
			},
			actionBtnName: 'submit_copy',
		});
	}
}
