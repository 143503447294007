import {
	ChangeDetectionStrategy,
	Component,
	inject,
	input,
	output,
	signal,
	WritableSignal,
	OnInit,
	DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import {
	ICMMSLocation,
	LocationInput,
} from '@app/pages/cmms/interfaces/cmms-global.interfaces';
import { LocationService } from '@app/pages/cmms/services/location.service';
import { LocationSelectorService } from '@app/shared/services/location-selector.service';

@Component({
	selector: 'locations-chip',
	templateUrl: './locations-chip.component.html',
	styleUrl: './locations-chip.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatChipsModule, MatIconModule],
})
export class LocationsChipComponent implements OnInit {
	private readonly locationService = inject(LocationService);
	private readonly locationSelectorService = inject(LocationSelectorService);
	private readonly destroyRef = inject(DestroyRef);

	selectedLocations = input<ICMMSLocation[]>();
	locationsChanged = output<string[]>();
	locationAncestry = output<ICMMSLocation[]>();
	fullAncestryLocations: WritableSignal<Partial<ICMMSLocation[]>> = signal<
		ICMMSLocation[]
	>([]);

	ngOnInit() {
		this.fullAncestryLocations.set(this.selectedLocations());
		this.setLocationAncestry();

		this.locationSelectorService.locationChanged
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((location: string[]) => {
				this.locationsChanged.emit(location);
			});
	}

	setLocationAncestry() {
		const filteredLocations = this.selectedLocations().filter(
			location => location?.locationType.toUpperCase() !== 'BUILDING'
		);

		if (!filteredLocations.length) {
			this.fullAncestryLocations.set(this.selectedLocations());
			return;
		}
		this.locationService
			.getLocationAncestry({
				childIds: filteredLocations.map(location =>
					location.locationId ? location.locationId : location.id
				),
			})
			.subscribe(data => {
				data.ancestry.forEach(ancestry => {
					this.mapLocationAncestryData(ancestry, data.childId);
				});
			});
	}

	mapLocationAncestryData(ancestry: LocationInput[], childId: string[]) {
		const locationWithAncestry = {
			...ancestry[0],
			name: this.locationService.fullLocationAncestry(ancestry),
		};
		const updatedLocation = this.selectedLocations().filter(
			location =>
				location.locationId !== childId.find(id => id === ancestry[0].locationId)
		);
		this.fullAncestryLocations.set([
			...updatedLocation,
			locationWithAncestry,
		] as ICMMSLocation[]);
		this.locationAncestry.emit(this.fullAncestryLocations());
	}

	openLocationDialog() {
		this.locationSelectorService.openLocationDialog();
	}
}
