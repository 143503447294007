import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnChanges,
	OnInit,
	output,
	signal,
	WritableSignal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormFieldLabelComponent } from '@app/shared/ui-components/form-field-label/form-field-label.component';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { MatSelectModule } from '@angular/material/select';
import {
	ConsequenceRatingChoicesTypeTranslationKeys,
	CostAllocationTypeTranslationKeys,
	DeviationTypeTranslationKeys,
	MaintenanceSelectionConfiguration,
	MaintenanceSelectionTypes,
	MaintenanceTypes,
	RiskLevelTypeTranslationKeys,
	TimeframeTypeTranslationKeys,
} from '@app/pages/cmms/configs/cmms-maintenance.enums';
import { maintenanceOptionsQuery } from '@app/pages/cmms/graphql/maintenance.queries';
import { Apollo } from 'apollo-angular';
import { map, take } from 'rxjs';
import { IMaintenanceBackendOptions, MaintenanceEnumValues } from '@app/domain/types';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'maintenance-selector',
	templateUrl: './maintenance-selector.component.html',
	styleUrl: './maintenance-selector.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		MatFormFieldModule,
		FormFieldLabelComponent,
		MatSelectModule,
		NgxTolgeeModule,
		ReactiveFormsModule,
	],
})
export class MaintenanceSelectorComponent implements OnInit, OnChanges {
	private readonly apollo = inject(Apollo);
	private readonly destroyRef = inject(DestroyRef);
	maintenanceType = input<MaintenanceSelectionTypes>();
	selectedMaintenance = input<string>();

	maintenanceChanged = output<string>();

	maintenanceForm = new FormControl();
	maintenanceOptions: WritableSignal<
		{
			name: MaintenanceTypes;
			description: string;
		}[]
	> = signal([{ name: null, description: '' }]);
	label: WritableSignal<string> = signal('');

	ngOnInit(): void {
		this.apollo
			.query<IMaintenanceBackendOptions>({
				query: maintenanceOptionsQuery[this.maintenanceType()],
			})
			.pipe(
				take(1),
				map((response: ApolloQueryResult<IMaintenanceBackendOptions>) => {
					return response.data.__type;
				})
			)
			.subscribe((maintenanceOptions: MaintenanceEnumValues) => {
				this.label.set(MaintenanceSelectionConfiguration[this.maintenanceType()].label);
				const mappedEnumValues = maintenanceOptions.enumValues.map(({ name }) => ({
					name,
					description: this.getTranslationKeyForEnum(this.maintenanceType(), name),
				}));
				this.maintenanceOptions.set(mappedEnumValues);
			});
		this.maintenanceForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((maintenance: string) => {
				this.maintenanceChanged.emit(maintenance);
			});
	}

	ngOnChanges(): void {
		this.maintenanceForm.setValue(this.selectedMaintenance());
	}

	getTranslationKeyForEnum(type: MaintenanceSelectionTypes, name: string): string {
		if (!name) return '';

		switch (type) {
			case 'DeviationTypeChoices':
				return DeviationTypeTranslationKeys[name];
			case 'TimeframeChoices':
				return TimeframeTypeTranslationKeys[name];
			case 'CostAllocationChoices':
				return CostAllocationTypeTranslationKeys[name];
			case 'RiskLevelChoices':
				return RiskLevelTypeTranslationKeys[name];
			case 'ConsequenceRatingChoices':
				return ConsequenceRatingChoicesTypeTranslationKeys[name];
			default:
				return name.toLowerCase();
		}
	}
}
