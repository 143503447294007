import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '@shared/validators/no-white-spaces.validator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { SpeechRecognitionService } from '@app/ai-chat/services/speech-recognition.service';
import { effect } from '@angular/core';
import { FeatureFlagService } from '@core/services/feature-flag.service';

@Component({
	selector: 'chat-input-field',
	imports: [CommonModule, MatIconModule, ReactiveFormsModule, NgxTolgeeModule],
	templateUrl: './chat-input-field.component.html',
	styleUrls: ['./chat-input-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputFieldComponent implements OnInit {
	private readonly aiChatService = inject(AiChatService);
	private readonly destroyRef = inject(DestroyRef);
	private readonly speechRecognition = inject(SpeechRecognitionService);
	readonly featureFlagService = inject(FeatureFlagService);

	@ViewChild('messageInput') textArea: ElementRef<HTMLTextAreaElement>;

	@Input() maxRows = 4;
	@Input() lineHeight = 26;

	formControl = new FormControl<string>(
		{
			value: '',
			disabled: this.aiChatService.isLoadingReply(),
		},
		{
			validators: [Validators.required, noWhitespaceValidator()],
			nonNullable: true,
		}
	);

	isLoadingReply = this.aiChatService.isLoadingReply;
	isRecording$ = this.speechRecognition.isRecording$;
	audioLevel$ = this.speechRecognition.audioLevel$;
	isSpeechSupported =
		this.speechRecognition.isSupported() && this.featureFlagService.isFFEnabled('asr');

	getWaveScale(index: number, audioLevel: number = 0): number {
		const baseScale = 1 + index * 0.2;
		const audioImpact = audioLevel * (1 + index);
		return baseScale + audioImpact;
	}

	ngOnInit() {
		effect(() => {
			if (this.isLoadingReply()) {
				this.formControl.disable({ emitEvent: false });
			} else {
				this.formControl.enable({ emitEvent: false });
			}
		});

		this.aiChatService.activeChatChanged$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.clearInputAndRestoreHeight());
	}

	adjustRows(textArea: HTMLTextAreaElement) {
		textArea.style.height = 'auto';
		const rows = Math.min(this.maxRows, textArea.scrollHeight / this.lineHeight);
		textArea.style.height = `${rows * this.lineHeight}px`;
	}

	submitRequestToAiChat() {
		if (this.formControl.valid && !this.isLoadingReply()) {
			const message = this.formattedValue();
			this.aiChatService.submitRequest(message);
			this.clearInputAndRestoreHeight();
		}
	}

	trackHotKeys($event: KeyboardEvent) {
		if ($event.key === 'Enter' && !$event.shiftKey) {
			$event.preventDefault();
			this.submitRequestToAiChat();
		}
	}

	toggleRecording() {
		if (this.speechRecognition.isRecording()) {
			this.speechRecognition.stopRecording();
		} else {
			this.speechRecognition.startRecording(
				(text, isFinal) => {
					this.formControl.setValue(text.trim());
					this.adjustRows(this.textArea.nativeElement);

					if (isFinal) {
						this.submitRequestToAiChat();
					}
				},
				(error?: any) => {}
			);
		}
	}

	private clearInputAndRestoreHeight() {
		this.formControl.reset();
		if (this.textArea) {
			this.textArea.nativeElement.style.height = `${this.lineHeight}px`;
		}
	}

	private formattedValue(): string {
		return this.formControl.value?.trim() || '';
	}
}
