import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnChanges,
	OnInit,
	output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormFieldLabelComponent } from '@app/shared/ui-components/form-field-label/form-field-label.component';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'entity-description',
	imports: [
		MatFormFieldModule,
		FormFieldLabelComponent,
		NgxTolgeeModule,
		ReactiveFormsModule,
		FormsModule,
		MatInputModule,
	],
	templateUrl: './entity-description.component.html',
	styleUrl: './entity-description.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityDescriptionComponent implements OnInit, OnChanges {
	private readonly destroyRef = inject(DestroyRef);
	selectedDescription = input<string>();
	descriptionChanged = output<string>();

	descriptionForm = new FormControl();

	ngOnInit(): void {
		this.descriptionForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((description: string) => {
				this.descriptionChanged.emit(description);
			});
	}

	ngOnChanges(): void {
		this.descriptionForm.setValue(this.selectedDescription());
	}
}
