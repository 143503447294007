import gql from 'graphql-tag';
export const EnumValuesFragment = gql`
	fragment EnumValuesFragment on __Type {
		name
		enumValues {
			name
			description
		}
	}
`;
export const maintenanceOptionsQuery = {
	DeviationTypeChoices: gql`
		query getEnumValues {
			__type(name: "DeviationTypeChoices") {
				...EnumValuesFragment
			}
		}
		${EnumValuesFragment}
	`,
	TimeframeChoices: gql`
		query getEnumValues {
			__type(name: "TimeframeChoices") {
				...EnumValuesFragment
			}
		}
		${EnumValuesFragment}
	`,
	CostAllocationChoices: gql`
		query getEnumValues {
			__type(name: "CostAllocationChoices") {
				...EnumValuesFragment
			}
		}
		${EnumValuesFragment}
	`,
	RiskLevelChoices: gql`
		query getEnumValues {
			__type(name: "RiskLevelChoices") {
				...EnumValuesFragment
			}
		}
		${EnumValuesFragment}
	`,
	ConsequenceRatingChoices: gql`
		query getEnumValues {
			__type(name: "ConsequenceRatingChoices") {
				...EnumValuesFragment
			}
		}
		${EnumValuesFragment}
	`,
};
