import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { PasswordReset } from '@app/auth/state/auth.actions';
import { Observable, Subject } from 'rxjs';
import { getAuthTranslate, getEmailSentStatus } from '@app/auth/state/auth.selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	MatCard,
	MatCardHeader,
	MatCardTitle,
	MatCardSubtitle,
	MatCardContent,
} from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, AsyncPipe, TitleCasePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'app-recovery-password',
	templateUrl: './recovery-password.component.html',
	styleUrls: ['./recovery-password.component.scss'],
	imports: [
		MatCard,
		FlexModule,
		MatCardHeader,
		MatIcon,
		MatCardTitle,
		MatCardSubtitle,
		MatCardContent,
		ReactiveFormsModule,
		MatFormField,
		MatInput,
		MatError,
		NgIf,
		MatButton,
		RouterLink,
		AsyncPipe,
		TitleCasePipe,
		NgxTolgeeModule,
	],
})
export class RecoveryPasswordComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();
	form: UntypedFormGroup;
	emailSent$: Observable<any>;
	authTranslation$: any;

	constructor(
		private fb: UntypedFormBuilder,
		private store$: Store<IAppState>,
		private snackBar: MatSnackBar
	) {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	ngOnInit() {
		this.authTranslation$ = this.store$.select(getAuthTranslate);
		this.emailSent$ = this.store$.select(getEmailSentStatus);
	}

	get email() {
		return this.form.controls.email;
	}

	onSubmit() {
		if (this.form.valid) {
			const val = this.form.value;
			const payload = {
				email: val.email,
			};
			this.store$.dispatch(new PasswordReset(payload));
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
		// need to have it if duration is infinite
		this.snackBar.dismiss();
	}
}
