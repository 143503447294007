import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

export type IAppState = object;

export const reducers: ActionReducerMap<IAppState> = {
	router: routerReducer,
};

export const metaReducers: MetaReducer<IAppState>[] = [];
