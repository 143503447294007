import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from '@auth/auth-routing.module';
import { LoginComponent } from '@auth/components/login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from './state/auth.reducer';
import { AuthEffects } from './state/auth.effects';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RecoveryPasswordComponent } from '@auth/components/recovery-password/recovery-password.component';
import { NewPasswordComponent } from '@auth/components/new-password/new-password.component';
import { AuthComponent } from './auth.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxTolgeeModule } from '@tolgee/ngx';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatCardModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		FormsModule,
		// TODO Check material modules usage
		MatDialogModule,
		FlexLayoutModule,
		AuthRoutingModule,
		StoreModule.forFeature('auth', fromAuth.authReducer),
		EffectsModule.forFeature([AuthEffects]),
		NgxTolgeeModule,
		LoginComponent,
		RecoveryPasswordComponent,
		NewPasswordComponent,
		AuthComponent,
		SetPasswordComponent,
	],
	exports: [LoginComponent, AuthComponent],
	providers: [],
})
export class AuthModule {}
