import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuDialogService } from '@app/core/services/au-dialog.service';
import { ICmmsAssignee } from '@app/pages/cmms/interfaces/assignee.interface';
import { IUserOrTeamMemberData } from '@app/pages/settings/users/interfaces/users-management.interfaces';
import { AssigneeSelectorComponent } from '@app/shared/action-center/components/assignee-selector/assignee-selector.component';
import { FormFieldLabelComponent } from '@app/shared/ui-components/form-field-label/form-field-label.component';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'entity-responsible',
	imports: [
		FormFieldLabelComponent,
		MatFormFieldModule,
		NgxTolgeeModule,
		ReactiveFormsModule,
		MatInputModule,
		MatIconModule,
	],
	templateUrl: './entity-responsible.component.html',
	styleUrl: './entity-responsible.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityResponsibleComponent {
	private readonly dialogService = inject(AuDialogService);

	responsibleType = input<'assignee' | 'responsible'>('assignee');
	selectedResponsible = input<ICmmsAssignee>();
	responsibleChanged = output<IUserOrTeamMemberData>();

	openResponsibleDialog() {
		const dialog: MatDialogRef<AssigneeSelectorComponent, IUserOrTeamMemberData[]> =
			this.dialogService.openComponentOverlayDialog(
				AssigneeSelectorComponent,
				'choose_responsible',
				'responsibleChanged'
			);

		dialog.afterClosed().subscribe((responsible: IUserOrTeamMemberData[]) => {
			if (!responsible) {
				return;
			}
			this.responsibleChanged.emit(responsible[0]);
		});
	}
}
