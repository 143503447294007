import {
	AfterViewInit,
	Component,
	computed,
	DestroyRef,
	inject,
	OnInit,
	signal,
	WritableSignal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { isNavSidebarCollapsed } from '@layout/state/layout.selectors';
import { NavSidebarCollapsed } from '@layout/state/layout.actions';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Apollo } from 'apollo-angular';
import { BeVersion } from '@core/api/version.query';
import { filter, pluck } from 'rxjs/operators';
import packageJson from '../../../../package.json';
import { TitleComponent } from '@layout/title/title.component';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { auNavbarConfig, settingsNavbarConfig } from '@app/configs/au-navbar.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavbarMenuService } from '@layout/au-nav-sidebar/navbar-menu.service';
import { NavbarMenuItemsComponent } from '@layout/au-nav-sidebar/navbar-menu-items/navbar-menu-items.component';
import { VersionCheckService } from '@app/core/version-check/version-check.service';
import { selectCurrentBuildingId } from '@app/pages/views/state/views.reducer';
import { NavbarNavigationService } from './navbar-navigation-service';
import { AuUtilsFunctions } from '@app/shared';
import { NavbarSizeService } from './navbar-size.service';
import { NgxTolgeeModule } from '@tolgee/ngx';

const defaultNavBarWidth = 212; // from src/assets/styles/variables.scss $nav-sidebar-full-width
const maxNavBarWidth = 450; // from src/assets/styles/variables.scss $nav-sidebar-max-width

@Component({
	selector: 'au-nav-sidebar',
	templateUrl: './au-nav-sidebar.component.html',
	styleUrls: ['./au-nav-sidebar.component.scss'],
	imports: [
		TitleComponent,
		MatListModule,
		MatRippleModule,
		CommonModule,
		MatIconModule,
		NavbarMenuItemsComponent,
		NgxTolgeeModule,
	],
	providers: [NavbarMenuService],
})
export class AuNavSidebarComponent implements OnInit, AfterViewInit {
	private readonly store$: Store = inject(Store);
	private readonly apollo = inject(Apollo);
	private readonly breakpointObserver = inject(BreakpointObserver);
	private readonly router = inject(Router);
	private readonly destroyRef = inject(DestroyRef);
	private readonly menuService = inject(NavbarMenuService);
	private readonly versionCheckService = inject(VersionCheckService);
	private readonly navbarNavigationService = inject(NavbarNavigationService);
	private readonly navbarSizeService = inject(NavbarSizeService);

	isCollapsed: boolean;
	isMobile = true;
	versionFE: string = packageJson.version;
	versionBE: string;

	navbarWidth = signal(this.navbarSizeService.getStoredWidth() ?? defaultNavBarWidth);
	enableTransition = signal(false);

	newVersionAvailable = this.versionCheckService.newVersionAvailable;
	currentBuildingId = this.store$.selectSignal(selectCurrentBuildingId);
	isSettingsRoute: WritableSignal<boolean> = signal(
		this.menuService.isSettingsRouteUrl(this.router.url)
	);
	navigationConfig = computed(() => {
		if (this.isSettingsRoute()) {
			return settingsNavbarConfig();
		}
		const menuItems = auNavbarConfig();

		menuItems.push({
			...this.navbarNavigationService.getChildViews(),
		});

		const storedMenuState = this.menuService.getStoredMenuState();
		this.menuService.applyStoredMenuState(menuItems, storedMenuState);
		return menuItems;
	});

	ngOnInit(): void {
		this.store$
			.select(isNavSidebarCollapsed)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(isCollapsed => (this.isCollapsed = isCollapsed));

		this.breakpointObserver
			.observe(['(max-width: 767px)'])
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((state: BreakpointState) => {
				this.isMobile = state.matches;
			});

		this.apollo
			.query({
				query: BeVersion,
			})
			.pipe(pluck('data', 'version'), takeUntilDestroyed(this.destroyRef))
			.subscribe((value: string) => (this.versionBE = value));

		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter(event => event instanceof NavigationEnd)
			)
			.subscribe((event: NavigationEnd) => {
				this.isSettingsRoute.set(this.menuService.isSettingsRouteUrl(event.url));
				this.navbarNavigationService.currentActiveRoute(event.url.substring(1));
			});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			// We wait to enable the css transition property until the stored width is set.
			// Otherwise, the menu will start to animate whenever the user loads the page.
			this.enableTransition.set(true);
		}, 100);
	}

	closeSidebar(): void {
		if (this.isMobile) {
			this.store$.dispatch(NavSidebarCollapsed());
		}
	}

	navigateBack(): void {
		const previousUrl = this.menuService.getPreviousNavigation();
		if (previousUrl) {
			this.router.navigateByUrl(previousUrl);
		} else {
			this.router.navigate(['/']);
		}
	}

	isEnabled(menuItems: any, value: string) {
		const menuItemIndex = menuItems.findIndex(item => item.routerLink === 'cmms');
		menuItems[menuItemIndex]?.children.push({
			title: AuUtilsFunctions.capitalizeText(value),
			routerLink: value,
		});
		return menuItems;
	}

	startResize($event: MouseEvent) {
		const initialX = $event.clientX;
		const initialWidth = this.navbarWidth();
		const getNewWidth = (event: MouseEvent) => {
			const diff = event.clientX - initialX;
			const newWidth = initialWidth + diff;
			if (newWidth < defaultNavBarWidth) {
				return defaultNavBarWidth;
			} else if (newWidth > maxNavBarWidth) {
				return maxNavBarWidth;
			}
			return newWidth;
		};
		const onMouseMove = (event: MouseEvent) => {
			this.navbarWidth.set(getNewWidth(event));
		};
		const onMouseUp = (event: MouseEvent) => {
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
			const newWidth = getNewWidth(event);
			this.navbarWidth.set(newWidth);
			this.navbarSizeService.setStoredWidth(newWidth);
		};
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
	}
}
