<div class="nice-scroll visible-scroll content-list">
	<entity-image-display [files]="config().data.files"> </entity-image-display>
	<div class="form-section">
		<entity-category
			[selectedCategory]="config().data.category"
			(categoryChanged)="updateDataFn({ category: $event })"
		/>

		<entity-priority
			[selectedPriority]="config().data.priority"
			(priorityChanged)="updateDataFn({ priority: $event })"
		></entity-priority>
	</div>

	<entity-description
		[selectedDescription]="config().data.description"
		(descriptionChanged)="updateDataFn({ description: $event })"
	></entity-description>

	<entity-labels-section
		[selectedLabels]="config().data.labels"
		(labelsChanged)="updateDataFn({ labels: $event })"
	></entity-labels-section>

	<div class="form-section">
		@if (config().entityType === 'WO') {
			<entity-deadline
				[selectedDeadline]="deadline()"
				(deadlineChanged)="updateDataFn({ deadline: $event })"
			/>
			<entity-schedule
				[selectedSchedule]="routineData()"
				(scheduleChanged)="updateDataFn($event)"
			/>
		}
	</div>
	<div class="form-section">
		<entity-responsible
			[responsibleType]="'assignee'"
			[selectedResponsible]="config().data.assignee"
			(responsibleChanged)="updateDataFn({ assignee: $event })"
		>
		</entity-responsible>
		<entity-responsible
			[responsibleType]="'responsible'"
			[selectedResponsible]="config().data.approver"
			(responsibleChanged)="updateDataFn({ approver: $event })"
		>
		</entity-responsible>
	</div>

	@if (config().entityType === 'WO') {
		<entity-maintenance
			[currentEntity]="config().data"
			(maintenanceChanged)="updateDataFn($event)"
		></entity-maintenance>
	}
	<entity-created-by
		[createdBy]="config().data.createdBy"
		[created]="config().data.created"
	></entity-created-by>
</div>
