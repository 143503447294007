import {
	IWorkOrder,
	IWorkOrderCreateVariables,
} from '../interfaces/work-order.interface';

export function workOrderIntoCreateWorkOrder(
	workOrder: IWorkOrder
): IWorkOrderCreateVariables {
	return {
		name: workOrder.name,
		priority: workOrder.priority,
		description: workOrder.description,
		locations: workOrder.locations,
		category: workOrder.category,
		timeframe: workOrder.timeframe,
		costOfWork: workOrder.costOfWork,
		deviationType: workOrder.deviationType,
		costAllocation: workOrder.costAllocation,
		deadline: workOrder.deadline as unknown as string,
		assignee: workOrder.assignee?.psId,
		assigneeType: workOrder.assignee?.contentType,
		approver: workOrder.approver?.psId,
		approverType: workOrder.approver?.contentType,
		routineData: workOrder.routineData,
		labels: workOrder.labels?.map(label => label.pk),
		assets: [],
	};
}
