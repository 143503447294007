import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
	selector: 'loading-spinner',
	imports: [MatProgressSpinnerModule],
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {}
