import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnInit,
	output,
} from '@angular/core';
import { FormFieldLabelComponent } from '../../ui-components/form-field-label/form-field-label.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'entity-deadline',
	standalone: true,
	imports: [
		FormFieldLabelComponent,
		MatFormFieldModule,
		MatDatepickerModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
	],
	templateUrl: './entity-deadline.component.html',
	styleUrl: './entity-deadline.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityDeadlineComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	selectedDeadline = input<string>();
	deadlineChanged = output<Date>();

	deadlineForm = new FormControl<Date>(null);

	ngOnInit() {
		this.deadlineForm.setValue(new Date(this.selectedDeadline()));
		this.deadlineForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(value => {
				this.deadlineChanged.emit(value);
			});
	}
}
