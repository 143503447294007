<div class="au-breadcrumbs-container">
	<nav>
		<ng-container *ngFor="let item of breadcrumbs(); let last = last">
			<div class="font-bold-large">
				<ng-container *ngIf="item.path">
					<a class="breadcrumb-link" [routerLink]="item.path">{{
						item.label | translate
					}}</a>
				</ng-container>
				<ng-container *ngIf="!item.path">
					<span>{{ item.label | translate }}</span>
				</ng-container>
			</div>
			<span *ngIf="!last"> > </span>
		</ng-container>
	</nav>

	<ng-container *ngIf="menu()?.items">
		<div mat-ripple class="ripple-container" [matMenuTriggerFor]="menuTrigger">
			<mat-icon svgIcon="more_vert"></mat-icon>
		</div>
	</ng-container>
</div>

<mat-menu #menuTrigger="matMenu" class="au-mat-menu-actions-list" xPosition="after">
	<div class="breadcrumbs-actions-menu">
		<ng-container *ngFor="let menuItem of menu()?.items">
			<mat-divider *ngIf="menuItem.divider" />
			<div
				class="menu-action"
				(click)="menuItem.action()"
				[ngClass]="menuItem.colorPalette + '-action'"
			>
				<mat-icon [svgIcon]="menuItem.icon"></mat-icon>
				<span>{{ menuItem.name | translate }}</span>
			</div>
		</ng-container>
	</div>
</mat-menu>
