<ng-container panel-content>
	<div class="images-container">
		@for (image of images(); track image.pk) {
			<ng-container>
				<div class="image-sections">
					<img [src]="image?.file?.url" alt="workorder image" />
				</div>
			</ng-container>
		}
	</div>
</ng-container>
