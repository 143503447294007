<div class="workOrderWorkspace">
	@if (!workOrderData() && !newWorkOrder()) {
		<loading-spinner />
	} @else {
		<entity-workspace-form
			[newEntity]="newWorkOrder()"
			[initialConfig]="formConfig()"
			(saveData)="saveData($event)"
			(createNewEntityCallback)="createNewEntity($event)"
		/>
	}
</div>
