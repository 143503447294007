import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnChanges,
	OnInit,
	output,
} from '@angular/core';
import { FormFieldLabelComponent } from '../../ui-components/form-field-label/form-field-label.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'entity-cost-form',
	imports: [
		FormFieldLabelComponent,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		NgxTolgeeModule,
	],
	templateUrl: './entity-cost-form.component.html',
	styleUrl: './entity-cost-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCostFormComponent implements OnInit, OnChanges {
	private readonly destroyRef = inject(DestroyRef);

	selectedCost = input<number>();
	costChanged = output<number>();

	costForm = new FormControl();

	ngOnInit(): void {
		this.costForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((cost: number) => {
				this.costChanged.emit(cost);
			});
	}

	ngOnChanges(): void {
		this.costForm.setValue(this.selectedCost());
	}
}
