<div class="database-playgrouond-container">
	<code>
		<!--					<pre>{{ locations$ | async | json }}</pre>-->
		<!--					<pre>{{ organizations$ | async | json }}</pre>-->
		<!--					<pre>{{ roomTypes$ | async | json }}</pre>-->
		<!--					<pre>{{ organizationTypes$ | async | json }}</pre>-->
		<!--					<pre>{{ teamTypes$ | async | json }}</pre>-->
		<pre>{{ assignees$ | async | json }}</pre>
	</code>
	<div style="display: flex; align-items: center">
		<button mat-flat-button (click)="addItems()" class="m-r-sxs">Add</button>
		<button mat-flat-button (click)="putItems()" class="m-r-sxs">Put</button>
		<button mat-flat-button (click)="clearDB()">Clear Db</button>
	</div>
</div>
