import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from '@core/error-page/error-page.component';
import { AuthModule } from '@auth/auth.module';
import { IconSnackBarComponent } from '@core/icon-snack-bar/icon-snack-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { ActionConfirmationComponent } from '@core/dialogs/action-confirmation/action-confirmation.component';
import { AddRoomComponent } from '@core/dialogs/add-room/add-room.component';
import { ChangeFloorNameComponent } from '@core/dialogs/change-floor-name/change-floor-name.component';
import { ConfirmSensorInstallationModalComponent } from '@core/dialogs/confirm-sensor-installation-modal/confirm-sensor-installation-modal.component';
import { DeleteConfirmComponent } from '@core/dialogs/delete-confirm/delete-confirm.component';
import { EditLocationModalComponent } from '@core/dialogs/edit-location/edit-location.component';
import { ImageCropperDialogComponent } from '@core/dialogs/image-cropper/image-cropper-dialog.component';
import { AuDirectivesModule } from '@shared/directives/au-directives.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { LoadingSnackBarComponent } from '@core/icon-snack-bar/loading-snack-bar/loading-snack-bar.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import config from '@app/configs/au-main-config';
import { AlertPopoverComponent } from '@core/dialogs/alert-popover/alert-popover.component';
import { AuTechnoBtnComponent } from '@app/shared/ui-components/au-techno-btn/au-techno-btn.component';
import { NgxTolgeeModule } from '@tolgee/ngx';

@NgModule({
	imports: [
		CommonModule,
		AuthModule,
		MatIconModule,
		MatDialogModule,
		AuTechnoBtnComponent,
		FlexModule,
		MatRippleModule,
		ImageCropperModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		MatInputModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatButtonModule,
		AuDirectivesModule,
		NgxMapboxGLModule.withConfig({
			accessToken: config.portal.mapboxKey,
		}),
		NgxTolgeeModule,
		ErrorPageComponent,
		IconSnackBarComponent,
		LoadingSnackBarComponent,
		ActionConfirmationComponent,
		AddRoomComponent,
		ChangeFloorNameComponent,
		ConfirmSensorInstallationModalComponent,
		DeleteConfirmComponent,
		EditLocationModalComponent,
		ImageCropperDialogComponent,
		AlertPopoverComponent,
	],
	exports: [ErrorPageComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
