import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { isNavSidebarCollapsed } from '@layout/state/layout.selectors';
import { NavSidebarExpanded, NavSidebarCollapsed } from '@layout/state/layout.actions';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AuBreadcrumbComponent } from '../../core/au-breadcrumb/au-breadcrumb.component';
import { TopbarActionButtonsComponent } from './topbar-action-buttons/topbar-action-buttons.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [MatIconButton, MatIcon, AuBreadcrumbComponent, TopbarActionButtonsComponent],
})
export class HeaderComponent implements OnInit, OnDestroy {
	private readonly store$ = inject(Store);

	isNavSidebarCollapsed: boolean;
	subscription: Subscription;

	ngOnInit() {
		this.subscription = this.store$
			.select(isNavSidebarCollapsed)
			.subscribe(isCollapsed => (this.isNavSidebarCollapsed = isCollapsed));
	}

	toggleSidebar() {
		if (this.isNavSidebarCollapsed) {
			this.store$.dispatch(NavSidebarExpanded());
		} else {
			this.store$.dispatch(NavSidebarCollapsed());
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
