import {
	ChangeDetectionStrategy,
	Component,
	computed,
	input,
	output,
} from '@angular/core';
import { STATUS_LABELS } from '@app/shared/ui-components/au-cmms-status-label/statuses';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'entity-status',
	templateUrl: './entity-status.component.html',
	styleUrl: './entity-status.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgxTolgeeModule],
})
export class EntityStatusComponent {
	selectedStatus = input<string>();
	statusChanged = output();

	statusName = computed(() => STATUS_LABELS[this.selectedStatus()].name);
}
