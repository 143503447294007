import {
	ChangeDetectionStrategy,
	Component,
	input,
	signal,
	WritableSignal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'locations-list',
	templateUrl: './locations-list.component.html',
	styleUrl: './locations-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatProgressSpinnerModule, NgxTolgeeModule, MatIconModule],
})
export class LocationsListComponent {
	selectedLocation = input<string>();

	filteredLocationList: WritableSignal<string[]> = signal<string[]>([]);
	loading: WritableSignal<boolean> = signal(false);
}
