<div
	class="navigation-overlay"
	[class.collapsed]="isCollapsed"
	[class.transition]="enableTransition()"
	[style.width]="navbarWidth() + 'px'"
>
	<div class="navigation-menu-container">
		<div class="navbar-content">
			@if (!isSettingsRoute()) {
				<app-title />
			} @else {
				<div class="au-settings-title-container" (click)="navigateBack()">
					<div class="back-button">
						<mat-icon svgIcon="nav_arrow_backward" />
					</div>
					<p class="settings-title">{{ 'settings' | translate }}</p>
				</div>
			}
			<navbar-menu-items
				[menuItems]="navigationConfig()"
				[isSettingsRoute]="isSettingsRoute()"
			/>
		</div>

		<div class="bottom-wrapper">
			<div class="version">
				<div>
					v{{ versionFE }}
					@if (newVersionAvailable()) {
						<span>{{ '> ' }}{{ newVersionAvailable() }}</span>
					}
				</div>
				<div>{{ versionBE }}</div>
			</div>
		</div>
	</div>
	<div class="resizer" (mousedown)="startResize($event)"></div>
</div>
