import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuRightSidebarComponent } from './au-right-sidebar.component';
import { PortalModule } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { LetDirective } from '@ngrx/component';
import { AuTechnoBtnComponent } from '@app/shared/ui-components/au-techno-btn/au-techno-btn.component';
import { MatButtonModule } from '@angular/material/button';
import { NgxTolgeeModule } from '@tolgee/ngx';
@NgModule({
	imports: [
		CommonModule,
		PortalModule,
		MatIconModule,
		AuTechnoBtnComponent,
		MatRippleModule,
		LetDirective,
		MatButtonModule,
		NgxTolgeeModule,
		AuRightSidebarComponent,
	],
	exports: [AuRightSidebarComponent],
})
export class AuRightSidebarModule {}
