{
  "name": "frontend",
  "version": "1.25.591",
  "scripts": {
    "ng": "ng",
    "start": "yarn run create-version && ng serve",
    "start-twin": "yarn run create-version && ng serve --configuration=twin-hmr",
    "build": "yarn run create-version && ng build --configuration=production",
    "build-pr": "yarn run create-version && ng build --configuration=pr",
    "build-dev": "yarn run create-version && ng build --configuration=stage",
    "build-qa": "yarn run create-version && ng build --configuration=qa && yarn run sentry:sourcemaps",
    "create-version": "node ./create-version-file.js",
    "test": "ng test",
    "test:ci": "node --max_old_space_size=6122 ../node_modules/@angular/cli/bin/ng test --karma-config=karma-ci.conf.js",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "increment": "git pull && yarn version -i patch && git commit -a -m 'Raise patch version;' && git push",
    "publish": "git tag -a \"v$(node -p \"require('./package.json').version\")\" -m 'Create tag version;' && git push --tags",
    "publish-master-to-prod": "git checkout master && yarn run increment && yarn run publish",
    "publish-qa-to-qa": "git checkout qa && yarn run increment",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org autility-edc11cf12 --project javascript-angular ./dist && sentry-cli sourcemaps upload --org autility-edc11cf12 --project javascript-angular ./dist",
    "pull-translations": "tolgee pull --api-key tgpak_geztcojql42ws43tny2wi3lunztw2m3tha4dimrqnjvdgolhgrxq",
    "push-translations": "tolgee push --api-key tgpak_geztcojql42ws43tny2wi3lunztw2m3tha4dimrqnjvdgolhgrxq"
  },
  "private": true,
  "dependencies": {
    "@amcharts/amcharts4": "^4.10.39",
    "@amcharts/amcharts5": "^5.10.7",
    "@angular/animations": "^19.1.5",
    "@angular/cdk": "^19.1.5",
    "@angular/common": "^19.1.5",
    "@angular/compiler": "^19.1.5",
    "@angular/core": "^19.1.5",
    "@angular/flex-layout": "^15.0.0-beta.42",
    "@angular/forms": "^19.1.5",
    "@angular/material": "^19.1.5",
    "@angular/platform-browser": "^19.1.5",
    "@angular/platform-browser-dynamic": "^19.1.5",
    "@angular/router": "^19.1.5",
    "@apollo/client": "^3.11.10",
    "@autility/digital-twin-service": "workspace:library",
    "@mapbox/mapbox-gl-geocoder": "^5.0.3",
    "@netcreaties/ngx-smart-banner": "^2.0.0-1",
    "@ngrx/component": "^19.0.1",
    "@ngrx/effects": "^19.0.1",
    "@ngrx/entity": "^19.0.1",
    "@ngrx/router-store": "^19.0.1",
    "@ngrx/store": "^19.0.1",
    "@ngrx/store-devtools": "^19.0.1",
    "@sentry/angular": "~9.1.0",
    "@supy-io/ngx-intercom": "^14.2.12",
    "@tolgee/format-icu": "^6.0.1",
    "@tolgee/ngx": "^6.0.0",
    "apollo-angular": "^7.2.1",
    "dexie": "^3.2.7",
    "extract-files": "^13.0.0",
    "graphql": "^16.9.0",
    "graphql-tag": "^2.12.6",
    "hammerjs": "^2.0.8",
    "keycloak-angular": "16.1.0",
    "keycloak-js": "21.1.2",
    "lz-string": "^1.5.0",
    "mapbox-gl": "^2.15.0",
    "ngx-doc-viewer": "^15.0.1",
    "ngx-image-cropper": "^6.3.4",
    "ngx-mapbox-gl": "^11.0.1",
    "ngx-mask": "^16.4.2",
    "ngx-pendo": "^1.14.3",
    "ngx-quill": "^27.0.1",
    "quill": "^2.0.3",
    "quill-mention": "^6.0.2",
    "rxjs": "^7.8.1",
    "socket.io": "^4.8.1",
    "socket.io-client": "^2.5.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.5",
    "@angular-devkit/core": "^19.1.5",
    "@angular-devkit/schematics": "^19.1.5",
    "@angular-eslint/builder": "^19.1.0",
    "@angular-eslint/eslint-plugin": "^19.1.0",
    "@angular-eslint/eslint-plugin-template": "^19.1.0",
    "@angular-eslint/schematics": "^19.1.0",
    "@angular-eslint/template-parser": "^19.1.0",
    "@angular/cli": "^19.1.5",
    "@angular/compiler-cli": "^19.1.5",
    "@angular/language-service": "^19.1.5",
    "@html-eslint/eslint-plugin": "^0.27.0",
    "@html-eslint/parser": "^0.27.0",
    "@ngrx/schematics": "^19.0.1",
    "@schematics/angular": "^19.1.5",
    "@sentry/cli": "^2.42.5",
    "@tolgee/cli": "^2.4.0",
    "@types/jasmine": "^5.1.4",
    "@types/lz-string": "^1.5.0",
    "@types/mapbox-gl": "^2.7.21",
    "@types/mapbox__mapbox-gl-geocoder": "^4.7.7",
    "@types/node": "^20.17.6",
    "@types/node-fetch": "^2.6.12",
    "@typescript-eslint/eslint-plugin": "^8.24.1",
    "@typescript-eslint/parser": "^8.24.1",
    "@typescript-eslint/types": "^8.24.1",
    "@typescript-eslint/utils": "^8.24.1",
    "cpy": "^11.1.0",
    "cpy-cli": "^5.0.0",
    "esbuild": "^0.17.19",
    "eslint": "^8.57.1",
    "eslint-plugin-local-rules": "^3.0.2",
    "jasmine-core": "^5.4.0",
    "jasmine-marbles": "^0.9.2",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "prettier": "^3.5.1",
    "sass": "^1.81.0",
    "ts-node": "^10.9.2",
    "typescript": "~5.7.0"
  }
}
