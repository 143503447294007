<form-field-label [labelTranslateKey]="'cost'"></form-field-label>

<mat-form-field floatLabel="always" class="cost-container">
	<input
		matInput
		type="number"
		step="1000"
		min="0"
		[placeholder]="'cost' | translate"
		[formControl]="costForm"
	/>
	<span matTextSuffix>kr&nbsp;</span>
</mat-form-field>
