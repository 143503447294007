import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnChanges,
	OnInit,
	output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { PRIORITY_LEVELS } from '@app/pages/cmms/configs/priority-levels';
import { FormFieldLabelComponent } from '@app/shared/ui-components/form-field-label/form-field-label.component';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'entity-priority',
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		NgxTolgeeModule,
		FormFieldLabelComponent,
		ReactiveFormsModule,
		MatIconModule,
		CommonModule,
	],
	templateUrl: './entity-priority.component.html',
	styleUrl: './entity-priority.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityPriorityComponent implements OnInit, OnChanges {
	private readonly destroyRef = inject(DestroyRef);
	selectedPriority = input<number>();
	priorityChanged = output<number>();

	priority = new FormControl();

	defaultPriorities = PRIORITY_LEVELS;

	ngOnInit() {
		this.priority.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(value => {
				this.priorityChanged.emit(value.level);
			});
	}

	ngOnChanges(): void {
		this.priority.setValue(
			this.defaultPriorities.find(p => p.level === this.selectedPriority())
		);
	}
}
