import { Component, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	ReactiveFormsModule,
} from '@angular/forms';
import { getAuthTranslate } from '@auth/state/auth.selectors';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { ActivatedRoute, Router } from '@angular/router';
import { SendNewPassword } from '@app/auth/state/auth.actions';
import {
	CrossFieldErrorMatcher,
	validatePasswordMatch,
} from '../../validators/password.validator';
import { AuPasswordInput } from '@shared/au-password-input';
import {
	MatCard,
	MatCardHeader,
	MatCardTitle,
	MatCardContent,
} from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, AsyncPipe, TitleCasePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'app-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['./new-password.component.scss'],
	imports: [
		MatCard,
		FlexModule,
		MatCardHeader,
		MatIcon,
		MatCardTitle,
		MatCardContent,
		ReactiveFormsModule,
		MatFormField,
		MatInput,
		MatSuffix,
		MatError,
		NgIf,
		MatButton,
		AsyncPipe,
		TitleCasePipe,
		NgxTolgeeModule,
	],
})
export class NewPasswordComponent extends AuPasswordInput implements OnInit {
	form: UntypedFormGroup;
	items$: any;
	private token: string;
	errorMatcher = new CrossFieldErrorMatcher();

	constructor(
		private fb: UntypedFormBuilder,
		private store$: Store<IAppState>,
		protected activeRoute: ActivatedRoute,
		protected router: Router
	) {
		super();
	}

	ngOnInit() {
		this.items$ = this.store$.select(getAuthTranslate);
		this.token = this.activeRoute.snapshot.queryParams.token;
		this.initForm();
	}

	initForm() {
		this.form = this.fb.group(
			{
				password: [
					'',
					[
						Validators.required,
						this.validateByPattern(new RegExp('^(?=.*[A-Z])'), {
							uppercase: true,
						}),
						this.validateByPattern(new RegExp('^(?=.*[a-z])'), {
							lowercase: true,
						}),
						this.validateByPattern(new RegExp('^(?=.*[0-9])'), {
							number: true,
						}),
						this.validateByPattern(new RegExp('^(?=.{8,})'), {
							length: true,
						}),
					],
				],
				confirmPassword: [''],
			},
			{
				validators: validatePasswordMatch,
			}
		);
	}

	checkError(errorCode: string): boolean {
		return this.password.value?.length ? this.password.hasError(errorCode) : false;
	}

	onSubmit() {
		if (this.form.valid) {
			const val = this.form.value;
			const payload = {
				password: val.password,
				token: this.token,
			};
			if (val.password === val.confirmPassword) {
				this.store$.dispatch(new SendNewPassword(payload));
			}
		}
	}

	get password() {
		return this.form.controls.password;
	}

	get passwordErrorCount() {
		return this.form.controls.password.errors
			? Object.keys(this.form.controls.password.errors).length
			: 0;
	}
}
