<mat-card appearance="outlined" fxFlex fxLayout="column" fxLayoutAlign="start center">
	<mat-card-header fxLayout="column">
		<mat-icon class="logo" svgIcon="logo" fxFlexOrder="0"></mat-icon>
		<mat-card-title fxFlexOrder="1">
			<h2>{{ 'set_password_title' | translate }}</h2>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="form" (ngSubmit)="form.valid && onSubmit()">
			<mat-form-field hideRequiredMarker>
				<input
					#newPassword
					autocomplete="new_password"
					autofocus
					type="password"
					matInput
					placeholder="{{ 'password' | translate }}"
					formControlName="password"
				/>
				<mat-icon
					class="pointer"
					(click)="toggleInputType($event, newPassword)"
					matSuffix
					[svgIcon]="getInputIcon(newPassword.type)"
				></mat-icon>
				<mat-error *ngIf="passwordRequirementsNotMet">
					{{ 'validation_password_requirements_not_met' | translate }}
				</mat-error>
			</mat-form-field>

			<mat-form-field hideRequiredMarker>
				<input
					#passwordRepeat
					autocomplete="new_password_repeat"
					type="password"
					matInput
					placeholder="{{ 'repeat_password' | translate }}"
					formControlName="confirmPassword"
				/>
				<mat-icon
					class="pointer"
					(click)="toggleInputType($event, passwordRepeat)"
					matSuffix
					[svgIcon]="getInputIcon(passwordRepeat.type)"
				></mat-icon>
				<mat-error *ngIf="confirmPassword.hasError('mustMatch')">
					{{ 'validation_passwords_mismatch' | translate }}
				</mat-error>
			</mat-form-field>

			<button mat-raised-button color="primary" type="submit" mat-button>
				{{ 'set_password' | translate }}
			</button>
		</form>
		<div>
			<p>
				{{ 'validation_password_requirements_full' | translate }}
			</p>
		</div>
	</mat-card-content>
</mat-card>
