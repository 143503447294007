import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SpeechRecognitionService {
	private recognition: any;
	private audioContext: AudioContext;
	private analyzer: AnalyserNode;
	private mediaStream: MediaStream;
	private isRecordingSubject = new BehaviorSubject<boolean>(false);
	private audioLevelSubject = new BehaviorSubject<number>(0);
	isRecording$ = this.isRecordingSubject.asObservable();
	audioLevel$ = this.audioLevelSubject.asObservable();
	private currentTranscript = '';
	private languageSubject = new BehaviorSubject<string>('nb-NO');
	currentLanguage$ = this.languageSubject.asObservable();
	private animationFrameId: number;

	private readonly languages = {
		'nb-NO': 'Norwegian',
		'en-US': 'English',
	};

	constructor() {
		const SpeechRecognition =
			(window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
		if (SpeechRecognition) {
			this.recognition = new SpeechRecognition();
			this.recognition.continuous = true;
			this.recognition.interimResults = true;
			this.recognition.maxAlternatives = 1;
			this.recognition.lang = this.languageSubject.getValue();
		}
	}

	private async setupAudioContext() {
		try {
			this.audioContext = new AudioContext();
			this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
			const source = this.audioContext.createMediaStreamSource(this.mediaStream);
			this.analyzer = this.audioContext.createAnalyser();
			this.analyzer.fftSize = 256;
			source.connect(this.analyzer);
			this.startAudioLevelDetection();
		} catch (error) {
			console.error('Error setting up audio context:', error);
		}
	}

	private startAudioLevelDetection() {
		const dataArray = new Uint8Array(this.analyzer.frequencyBinCount);

		const detectLevel = () => {
			this.analyzer.getByteFrequencyData(dataArray);
			const average = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
			const normalizedLevel = average / 255;
			this.audioLevelSubject.next(normalizedLevel);

			this.animationFrameId = requestAnimationFrame(detectLevel);
		};

		detectLevel();
	}

	private cleanupAudio() {
		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = undefined;
		}
		if (this.mediaStream) {
			this.mediaStream.getTracks().forEach(track => track.stop());
			this.mediaStream = undefined;
		}
		if (this.audioContext?.state !== 'closed') {
			try {
				this.audioContext.close();
			} catch (error) {
				// Ignore errors when closing AudioContext
			}
		}
		this.audioContext = undefined;
		this.analyzer = undefined;
		this.audioLevelSubject.next(0);
	}

	startRecording(
		onResult: (text: string, isFinal: boolean) => void,
		onEnd: (error?: any) => void
	): boolean {
		if (!this.recognition) {
			onEnd('Speech recognition not supported');
			return false;
		}

		this.currentTranscript = '';
		this.setupAudioContext();

		this.recognition.onresult = (event: any) => {
			let interimTranscript = '';
			let finalTranscript = '';

			for (let i = 0; i < event.results.length; i++) {
				const result = event.results[i];
				if (result.isFinal) {
					finalTranscript += result[0].transcript;

					if (result[0].confidence < 0.5) {
						this.toggleLanguage();
					}
				} else {
					interimTranscript += result[0].transcript;
				}
			}

			// Update the current transcript with final results
			if (finalTranscript) {
				this.currentTranscript = (this.currentTranscript + ' ' + finalTranscript).trim();
			}

			// Send the complete text (current transcript + any interim results)
			const fullTranscript = (this.currentTranscript + ' ' + interimTranscript).trim();
			onResult(fullTranscript, !!finalTranscript);

			// If we have a final result and no interim results, stop recording
			if (finalTranscript && !interimTranscript) {
				this.stopRecording();
				onEnd(); // Normal end after final result
			}
		};

		this.recognition.onend = () => {
			this.isRecordingSubject.next(false);
			this.cleanupAudio();
			// Only call onEnd if we haven't already called it from onresult
			if (this.currentTranscript === '') {
				onEnd();
			}
		};

		this.recognition.onerror = (event: any) => {
			if (event.error === 'no-speech' || event.error === 'audio-capture') {
				this.toggleLanguage();
				// Don't treat these as errors, just language switch attempts
				return;
			}

			// Stop recording on critical errors
			if (event.error === 'network' || event.error === 'not-allowed') {
				this.stopRecording();
			}

			onEnd(event.error);
		};

		this.recognition.start();
		this.isRecordingSubject.next(true);
		return true;
	}

	private toggleLanguage() {
		const currentLang = this.getCurrentLanguage();
		const newLang = currentLang === 'en-US' ? 'nb-NO' : 'en-US';

		this.setLanguage(newLang);

		// Restart recognition with new language
		if (this.isRecording()) {
			this.recognition.stop();
			setTimeout(() => {
				if (this.isRecording()) {
					this.recognition.start();
				}
			}, 100);
		}
	}

	stopRecording() {
		if (this.recognition) {
			this.recognition.stop();
			this.isRecordingSubject.next(false);
			this.cleanupAudio();
		}
	}

	isSupported(): boolean {
		return !!this.recognition;
	}

	isRecording(): boolean {
		return this.isRecordingSubject.getValue();
	}

	setLanguage(newLang: string) {
		if (this.languages[newLang]) {
			this.languageSubject.next(newLang);
			this.recognition.lang = this.languages[newLang];
		}
	}

	getCurrentLanguage(): string {
		return this.languageSubject.getValue();
	}

	getAvailableLanguages(): { code: string; name: string }[] {
		return Object.entries(this.languages).map(([code, name]) => ({
			code,
			name,
		}));
	}
}
