import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EntityTitleIcons } from '@app/layout/new-right-sidebar/types';

@Component({
	selector: 'entity-title',
	standalone: true,
	imports: [MatIconModule],
	templateUrl: './entity-title.component.html',
	styleUrl: './entity-title.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityTitleComponent {
	name = input<string>();
	entityType = input<EntityTitleIcons>();

	iconEnum = EntityTitleIcons;
}
