<div class="entity-workspace-header">
	<entity-title [name]="config().data?.name" [entityType]="config().entityType" />
	@if (newEntity()) {
		<div>
			<div class="create-new-btn">
				<button
					mat-flat-button
					class="primary-btn mat-flat-btn-font-correction au-mat-btn-s save-button"
					(click)="createNewEntity()"
				>
					{{ 'save' | translate }}
				</button>
			</div>
		</div>
	}
	<div class="info-actions">
		<div class="info">
			@if (statuses()) {
				<entity-status
					[selectedStatus]="config().data.status"
					(statusChanged)="updateData($event)"
				/>
			}
			<div class="dot"></div>
			<div>
				<locations-chip
					[selectedLocations]="config().data.locations"
					(locationsChanged)="updateData({ locations: $event })"
					(locationAncestry)="locationAncestry.set($event)"
				/>
			</div>
		</div>

		<entity-workspace-actions
			[config]="config()"
			[locationAncestry]="locationAncestry()"
		></entity-workspace-actions>
	</div>
	<mat-tab-group class="fill-tab-height">
		<mat-tab [label]="'information' | translate">
			<information-tab
				class="tab-padding"
				(updateData)="updateData($event)"
				[config]="config()"
			/>
		</mat-tab>
		@if (showActivityTab()) {
			<mat-tab [label]="'activity' | translate" [disabled]="newEntity()">
				<ng-template [ngIf]="!newEntity() && showActivityTab()">
					<activity-tab
						class="tab-padding"
						(updateData)="updateData($event)"
						[config]="config()"
					/>
				</ng-template>
			</mat-tab>
		}
		@if (showChecklistTab()) {
			<mat-tab [label]="'checklist' | translate" [disabled]="newEntity()">
				<ng-template [ngIf]="!newEntity() && showActivityTab()">
					<checklist-tab
						class="tab-padding"
						(updateData)="updateData($event)"
						[data]="config().data"
					/>
				</ng-template>
			</mat-tab>
		}
	</mat-tab-group>
</div>
