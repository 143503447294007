import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	input,
	Output,
} from '@angular/core';
import { WorkOrderChecklistComponent } from '@app/pages/cmms/components/work-order/work-order-checklist/work-order-checklist.component';
import { IWorkOrder } from '@app/pages/cmms/interfaces/work-order.interface';

@Component({
	selector: 'checklist-tab',
	imports: [WorkOrderChecklistComponent],
	templateUrl: './checklist-tab.component.html',
	styleUrl: './checklist-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistTabComponent {
	data = input<IWorkOrder>();
	@Output() updateData = new EventEmitter<Partial<IWorkOrder>>();

	updateChecklist(template: string) {
		if (!template) {
			return;
		}
		const existingChecklist = this.data().checklists?.[0];

		if (!existingChecklist) {
			this.updateData.emit({
				checklists: [{ id: null, template: template }],
			});
			return;
		}
		this.updateData.emit({
			checklists: [{ ...existingChecklist, template: template }],
		});
	}

	deleteChecklist() {
		this.updateData.emit({
			checklists: [],
		});
		if (!this.data().checklists?.[0]?.id) {
			return;
		}
	}
}
