import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { MaintenanceSelectorComponent } from './maintenance-selector/maintenance-selector.component';
import { EntityCostFormComponent } from '../entity-cost-form/entity-cost-form.component';
import { IWorkOrder } from '@app/pages/cmms/interfaces/work-order.interface';
import { MaintenanceEntityDict } from '@app/pages/cmms/configs/cmms-maintenance.enums';

@Component({
	selector: 'entity-maintenance',
	imports: [NgxTolgeeModule, MaintenanceSelectorComponent, EntityCostFormComponent],
	templateUrl: './entity-maintenance.component.html',
	styleUrl: './entity-maintenance.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityMaintenanceComponent {
	currentEntity = input<IWorkOrder>();
	maintenanceChanged = output<MaintenanceEntityDict>();
}
