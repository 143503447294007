import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnChanges,
	OnInit,
	output,
} from '@angular/core';
import { ILabel } from '@app/pages/settings/interfaces/labels.interfaces';
import { LabelBadgeComponent } from '@app/pages/settings/labels/label-item-row/label-badge/label-badge.component';
import { FormFieldLabelComponent } from '../../ui-components/form-field-label/form-field-label.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LabelListMenuComponent } from '@app/pages/cmms/components/shared-form-group-controls/cmms-labels-section/label-list-menu/label-list-menu.component';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'entity-labels-section',
	imports: [
		LabelBadgeComponent,
		FormFieldLabelComponent,
		FormFieldLabelComponent,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		LabelListMenuComponent,
	],
	templateUrl: './entity-labels-section.component.html',
	styleUrl: './entity-labels-section.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityLabelsSectionComponent implements OnInit, OnChanges {
	private readonly destroyRef = inject(DestroyRef);
	selectedLabels = input<ILabel[]>();
	labelsChanged = output<ILabel[]>();

	labelsForm = new FormControl([]);

	ngOnInit() {
		this.labelsForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(labels => {
				this.labelsChanged.emit(labels);
			});
	}

	onSelectedLabelsChange($event: { pks: string[]; labels: ILabel[] }) {
		this.labelsForm.setValue($event.pks);
	}

	ngOnChanges() {
		this.labelsForm.setValue(this.selectedLabels());
	}
}
