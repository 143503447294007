<div
	class="right-sidebar-container"
	[class.fullscreen]="isFullscreenMode"
	*ngrxLet="sidebarConfig$ as sidebarConfig"
>
	<div class="right-sidebar-header">
		<div class="sidebar-nav-title">
			<div
				class="sidebar-nav-bar"
				*ngIf="sidebarService.isHistoryExisting && !sidebarConfig.attachmentMenu"
			>
				<mat-icon
					class="home"
					[class.active]="sidebarService.currentPageIndex !== 0"
					svgIcon="home-nav"
					(click)="navigateToHome()"
				></mat-icon>
				<mat-icon
					class="backward"
					[class.active]="sidebarService.isPreviousComponent"
					svgIcon="nav_arrow_backward"
					(click)="navigateToPrevious()"
				></mat-icon>
				<mat-icon
					class="forward"
					svgIcon="nav_arrow_forward"
					[class.active]="sidebarService.isNextComponent"
					(click)="navigateToFirst()"
				></mat-icon>
			</div>
			<div class="sidebar-header-title">
				<mat-icon *ngIf="!sidebarConfig.noIcon" [svgIcon]="sidebarConfig.icon"></mat-icon>
				<span class="uppercase">
					<ng-container *ngIf="typeof sidebarConfig.title !== 'string'">
						<ng-container *ngIf="sidebarConfig.title.prefix">
							{{ sidebarConfig.title.prefix | translate }}
						</ng-container>
						<ng-container *ngIf="sidebarConfig.title.text">
							{{ sidebarConfig.title.text | translate }}
						</ng-container>
						<ng-container *ngIf="sidebarConfig.title.endfix">
							{{ sidebarConfig.title.endfix }}
						</ng-container>
					</ng-container>
				</span>
			</div>
		</div>

		<div class="sidebar-view-actions">
			<mat-icon
				class="fullscreen-icon"
				*ngIf="!isFullscreenMode && sidebarConfig.allowFullscreen"
				(click)="fullscreen()"
				>open_in_full</mat-icon
			>
			<mat-icon
				class="fullscreen-icon"
				*ngIf="isFullscreenMode"
				(click)="exitFullscreen()"
				>close_fullscreen</mat-icon
			>
			<mat-icon (click)="minimize()">minimize</mat-icon>
			<mat-icon class="white-icon" (click)="close()">close</mat-icon>
		</div>
	</div>
	<div
		class="right-sidebar-content with-actions"
		[ngClass]="{
			'nice-scroll visible-scroll colored-scroll-track':
				!sidebarConfig.disableDefaultScroll,
		}"
	>
		<ng-template [cdkPortalOutlet]="sidebarService.sidebarPortal$ | async"></ng-template>
	</div>

	@if (sidebarConfig.showContentActions) {
		<div class="sidebar-content-actions">
			<button mat-button (click)="cancel()">{{ 'cancel' | translate }}</button>
			<button
				matRipple
				mat-flat-button
				[disabled]="disableButton"
				(click)="actionClicked()"
			>
				{{ sidebarConfig.actionBtnName | translate }}
			</button>
		</div>
	}
</div>
