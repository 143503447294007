import {
	ChangeDetectionStrategy,
	Component,
	inject,
	input,
	OnChanges,
	signal,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { EntityFormConfig } from '../../types';
import { CommonModule } from '@angular/common';
import { NewRightSidebarService } from '@app/layout/new-right-sidebar/services/new-right-sidebar/new-right-sidebar.service';
import { startDeletingWorkOrder } from '@app/pages/cmms/components/work-order/state/work-order.actions';
import { AuDialogService } from '@app/core/services/au-dialog.service';
import { Store } from '@ngrx/store';
import { ICmmsState } from '@app/pages/cmms/state/cmms.reducer';
import { startDeletingRequest } from '@app/pages/cmms/components/request/state/requests.action';
import { MatButtonModule } from '@angular/material/button';
import { NgxTolgeeModule, TranslateService } from '@tolgee/ngx';

@Component({
	selector: 'options-button',
	imports: [
		MatMenuModule,
		MatIconModule,
		MatRippleModule,
		CommonModule,
		MatButtonModule,
		NgxTolgeeModule,
	],
	templateUrl: './options-button.component.html',
	styleUrl: './options-button.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsButtonComponent implements OnChanges {
	private readonly sidebar = inject(NewRightSidebarService);
	private readonly store$ = inject(Store<ICmmsState>);
	private readonly dialogService = inject(AuDialogService);
	private readonly translateService = inject(TranslateService);

	// TODO: This component will be needed to be refactored as it is a bit messy now. Perhaps one component per button?

	config = input<EntityFormConfig>();

	openInDtConfig = signal({
		showButton: false,
		icon: null,
		text: null,
		buildingId: null,
		entityId: null,
		entityName: null,
	});

	ngOnChanges(): void {
		let coordinatesPresent = false;
		let buildingId = null;
		const openInDtConfigInput = this.openInDtConfig();

		const config = this.config();
		switch (config.entityType) {
			case 'WO':
				coordinatesPresent = config.data.coordinates?.x != null;

				buildingId = config.data.locations?.[0]?.locationId;
				openInDtConfigInput.entityId = config.data.workOrderId;
				openInDtConfigInput.entityName = 'work_order';
				openInDtConfigInput.showButton =
					config.data.locations?.[0]?.locationType === 'Building' && !!buildingId;
				break;
		}

		openInDtConfigInput.icon = coordinatesPresent ? 'digital-twin' : 'add_coordinates';
		openInDtConfigInput.text = coordinatesPresent ? 'show_in_twin' : 'place_in_twin';
		openInDtConfigInput.buildingId = buildingId;

		this.openInDtConfig.set(openInDtConfigInput);
	}

	delete() {
		const dialog = this.dialogService.openAlertDialog({
			title: this.translateService.instant('delete_work_order'),
			description: this.translateService.instant(`delete_work_order_confirmation`),
			actionBtnText: this.translateService.instant('delete'),
		});

		this.dialogService.closedByEnter(dialog).subscribe(approved => {
			if (!approved) {
				return;
			}
			this.sidebar.close();

			const config = this.config();
			const deleteAction = (function () {
				switch (config.entityType) {
					case 'WO':
						return startDeletingWorkOrder({ pk: config.data.pk });
					case 'REQUEST':
						return startDeletingRequest({ pk: config.data.pk });
				}
			})();
			this.store$.dispatch(deleteAction);
			this.sidebar.clear();
		});
	}
}
