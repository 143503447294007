<div class="container">
	<div class="header">
		@if (selectedLocation()) {
			<h2 class="selected-location">
				{{ selectedLocation() }}
			</h2>
		}

		<div class="horizontal-divider"></div>
	</div>
	@if (filteredLocationList().length === 0 && !loading()) {
		<div class="align-center">
			<mat-icon [svgIcon]="'sad-robot'"></mat-icon>
			<p class="no-results">{{ 'no_results_found' | translate }}</p>
		</div>
	} @else if (loading()) {
		<div class="align-center">
			<mat-spinner diameter="40"></mat-spinner>
		</div>
	} @else {}

	<div class="footer horizontal-divider"></div>
</div>
