import { Routes } from '@angular/router';
import { AiChatComponent } from '@app/ai-chat/ai-chat.component';
import { FilesEffects } from '@app/pages/cmms/components/files/state/files.effects';
import { provideEffects } from '@ngrx/effects';
import { RightSidebarRoutes } from './types';
import { WorkOrderWorkspaceComponent } from '@app/pages/workspace/components/work-order-workspace/work-order-workspace.component';
import { provideState } from '@ngrx/store';
import { CmmsFeatureKey, cmmsReducers } from '@app/pages/cmms/state/cmms.reducer';
import { WorkOrderEffects } from '@app/pages/cmms/components/work-order/state/work-order.effects';
import { RequestsEffects } from '@app/pages/cmms/components/request/state/requests.effects';

export const RIGHT_SIDEBAR_ROUTES: Routes = [
	{
		path: RightSidebarRoutes.AiChat,
		component: AiChatComponent,
		outlet: 'right-sidebar',
		providers: [provideEffects([FilesEffects])],
	},
	{
		path: `${RightSidebarRoutes.WorkOrder}/:pk`,
		component: WorkOrderWorkspaceComponent,
		outlet: 'right-sidebar',
		providers: [
			provideState(CmmsFeatureKey, cmmsReducers),
			provideEffects([WorkOrderEffects, RequestsEffects]),
		],
	},
];
