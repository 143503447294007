import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { IAttachedFile } from '@app/pages/cmms/interfaces/files.interfaces';

@Component({
	selector: 'entity-image-display',
	imports: [CommonModule],
	templateUrl: './entity-image-display.component.html',
	styleUrl: './entity-image-display.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityImageDisplayComponent {
	files = input<IAttachedFile[]>();

	images = computed(() =>
		this.files().filter(
			file =>
				file.file.contentType === 'image/jpeg' || file.file.contentType === 'image/png'
		)
	);
}
