<section class="chat-list-section" [class.d-none]="isCollapsed()">
	<chat-list-header />
	<div class="chat-items-wrapper nice-scroll colored-scroll-track">
		<div *ngIf="isLoadingChats()">
			<mat-spinner diameter="40"></mat-spinner>
		</div>
		<ng-container *ngFor="let chat of chats()">
			<div
				class="chat-item"
				[class.active]="chat.id === selectedChatId()"
				(click)="selectActiveChat(chat.id)"
			>
				<p>
					{{
						chat.buildingId ? chat.name : ('chatbot_new_chat' | translate) + ' ' + chat.id
					}}
				</p>
				<div class="chat-item-actions">
					<mat-icon
						svgIcon="delete"
						class="critical-icon"
						(click)="deleteChat(chat.id, $event)"
					/>
				</div>
			</div>
		</ng-container>
	</div>
</section>
<div
	class="collapse-bar"
	[matTooltip]="
		(isCollapsed() ? 'chatbot.open_sidebar' : 'chatbot.close_sidebar') | translate
	"
	matTooltipPosition="right"
	(click)="collapseChatList()"
></div>
