<div class="expanding">
	<div class="nice-scroll visible-scroll">
		<au-comments
			[commentsList]="config().data.comments"
			[requester]="requestees()"
			[isRequest]="isRequest()"
			(deleteComment)="deleteCommentConfirmation($event)"
			(editComment)="editComment($event)"
		/>
	</div>
	<comment-input
		[request]="request()"
		(commentSubmitted)="submitComment($event)"
		(newEntityClicked)="('newEntityFromComment($event)')"
		(areaClicked)="('scrollToBottom')"
	/>
</div>
