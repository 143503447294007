<button mat-icon-button [matMenuTriggerFor]="deleteBtnMenu">
	<mat-icon class="secondary-text-icon">more_vert</mat-icon>
</button>

<mat-menu #deleteBtnMenu="matMenu" xPosition="before" class="actions-menu">
	@if (openInDtConfig().showButton) {
		<button mat-menu-item>
			<mat-icon [svgIcon]="openInDtConfig().icon" class="dt-icon" />
			<span class="button-twin">{{ openInDtConfig().text | translate }}</span>
		</button>
	}
	<div class="divider"></div>
	<button mat-menu-item (click)="delete()">
		<mat-icon class="critical-icon" svgIcon="delete" />
		<span class="button-delete"> {{ 'menu_delete_option' | translate }} </span>
	</button>
</mat-menu>
