import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IWorkOrderRoutine } from '@app/pages/cmms/interfaces/work-order.interface';
import { FormFieldLabelComponent } from '@app/shared/ui-components/form-field-label/form-field-label.component';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'entity-schedule',
	imports: [FormFieldLabelComponent, NgxTolgeeModule, MatSlideToggleModule],
	templateUrl: './entity-schedule.component.html',
	styleUrl: './entity-schedule.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityScheduleComponent {
	selectedSchedule = input<IWorkOrderRoutine>();
	scheduleChanged = output<IWorkOrderRoutine>();

	implemented = false;
}
