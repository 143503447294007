import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuDialogService } from '@app/core/services/au-dialog.service';
import { cmmsEntityCategories } from '@app/pages/cmms/configs/cmms-entity-categories';
import { CategorySelectorComponent } from '@app/shared/action-center/components/category-selector/category-selector.component';
import { FormFieldLabelComponent } from '@app/shared/ui-components/form-field-label/form-field-label.component';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'entity-category',
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		NgxTolgeeModule,
		FormFieldLabelComponent,
		ReactiveFormsModule,
		MatInputModule,
		MatIconModule,
	],
	templateUrl: './entity-category.component.html',
	styleUrl: './entity-category.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCategoryComponent {
	private readonly dialogService = inject(AuDialogService);
	selectedCategory = input<string>();
	categoryChanged = output<string>();

	defaultCategories = cmmsEntityCategories;

	selectedCategoryValue = computed(() => {
		const category = this.defaultCategories.find(
			cat => cat.key === this.selectedCategory()
		);
		return category ? category.value : 'category_none';
	});

	openCategoryDialog() {
		const dialog: MatDialogRef<CategorySelectorComponent, string[]> =
			this.dialogService.openComponentOverlayDialog(
				CategorySelectorComponent,
				'choose_category',
				'categoryChanged'
			);

		dialog.afterClosed().subscribe((category: string[]) => {
			if (!category) {
				return;
			}
			this.categoryChanged.emit(category[0]);
		});
	}
}
