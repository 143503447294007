import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnInit,
	signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	IWorkOrder,
	IWorkOrderEditPayload,
} from '@app/pages/cmms/interfaces/work-order.interface';
import { WorkOrdersService } from '@app/pages/cmms/services/work-orders.service';
import { LoadingSpinnerComponent } from '@app/shared/ui-components/loading-spinner/loading-spinner.component';
import { take } from 'rxjs';
import { EntityWorkspaceFormComponent } from '../entity-workspace-form/entity-workspace-form.component';
import { IWorkOrderFormConfig } from '../../types';
import { Store } from '@ngrx/store';
import { workOrderMap } from '@app/pages/cmms/components/work-order/state/work-order.selectors';
import {
	addWorkOrder,
	startUpdateWorkOrder,
} from '@app/pages/cmms/components/work-order/state/work-order.actions';
import { AuUtilsFunctions } from '@app/shared';
import { ICmmsAssignee } from '@app/pages/cmms/interfaces/assignee.interface';
import { workOrderIntoCreateWorkOrder } from '@app/pages/cmms/utils-and-classes/work-order-transforms';
import { NewRightSidebarService } from '@app/layout/new-right-sidebar/services/new-right-sidebar/new-right-sidebar.service';
import {
	OpenSidebarEventTypes,
	RightSidebarRoutes,
} from '@app/layout/new-right-sidebar/types';
import { IconSnackBarService } from '@app/core/services/icon-snack-bar.service';
import { CMMS_STATUSES } from '@app/pages/cmms/configs/statuses-by-entity';
import { TranslateService } from '@tolgee/ngx';

@Component({
	selector: 'work-order-workspace',
	imports: [CommonModule, LoadingSpinnerComponent, EntityWorkspaceFormComponent],
	templateUrl: './work-order-workspace.component.html',
	styleUrls: ['./work-order-workspace.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrderWorkspaceComponent implements OnInit {
	private readonly route = inject(ActivatedRoute);
	// TODO: See if this can be handled with the state instead of a service
	private readonly workOrdersService = inject(WorkOrdersService);
	private readonly store$ = inject(Store);
	private readonly sidebarService = inject(NewRightSidebarService);
	private readonly notify = inject(IconSnackBarService);
	private readonly translateService = inject(TranslateService);

	formConfig = computed<IWorkOrderFormConfig>(() => {
		return {
			entityType: 'WO',
			data: this.workOrderData(),
		};
	});

	workOrderData = signal<IWorkOrder | null>(null);
	newWorkOrder = signal(false);

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			if (!params.pk) {
				this.newWorkOrder.set(true);
				this.workOrderData.set({
					priority: 2,
					status: CMMS_STATUSES.WORK_ORDERS[0],
				} as IWorkOrder);
				return;
			}
			this.newWorkOrder.set(false);
			this.loadWorkOrder(params.pk);
		});
	}

	private getWorkOrderFromBE(pk: string) {
		this.workOrdersService
			.getWorkOrderByPk(pk)
			.pipe(take(1))
			.subscribe((workOrder: IWorkOrder) => {
				if (!workOrder) {
					this.sidebarService.clear();
					this.notify.error(this.translateService.instant('work_order_not_found'));
					return;
				}
				this.workOrderData.set(workOrder);
			});
	}

	private loadWorkOrder(pk: string) {
		this.store$
			.select(workOrderMap)
			.pipe(take(1))
			.subscribe(workOrders => {
				const workOrder = workOrders[pk];
				if (workOrder) {
					this.workOrderData.set(workOrder);
					return;
				}
				this.getWorkOrderFromBE(pk);
			});
	}

	private changeApproverOrAssignee(
		updatedData: Partial<IWorkOrderEditPayload>,
		assigneeData: ICmmsAssignee | undefined,
		key: string
	) {
		if (assigneeData === undefined) return;
		updatedData[`${key}Type`] = assigneeData.contentType;
		updatedData[key] = assigneeData.psId;
	}

	private repeatableWOCompleted(workOrderData: Partial<IWorkOrderEditPayload>) {
		return workOrderData.status === 'COMPLETED' && this.workOrderData().routineData;
	}

	saveData(data: IWorkOrder) {
		const originalData = this.workOrderData();
		const changes: IWorkOrder = AuUtilsFunctions.diff(originalData, data);
		if (Object.keys(changes).length === 0) return;

		const updatedData = {
			...changes,
			labels: changes.labels?.map(label => label.pk),
			pk: originalData.pk,
		} as unknown as Partial<IWorkOrderEditPayload>;

		if (updatedData.checklists?.length > 0) {
			updatedData.checklists = updatedData.checklists.map(checklist => {
				return { template: checklist.template };
			});
		}

		this.changeApproverOrAssignee(updatedData, changes.assignee, 'assignee');
		this.changeApproverOrAssignee(updatedData, changes.approver, 'approver');

		if (this.repeatableWOCompleted(updatedData)) {
			updatedData['needUpdateTable'] = true;
		}
		this.store$.dispatch(startUpdateWorkOrder({ workOrder: updatedData }));
	}

	createNewEntity(data: IWorkOrder) {
		const requiredFields = ['name'];
		const missingFields = requiredFields.filter(field => !data[field]);
		if (missingFields.length > 0) {
			const translatedFields = missingFields.map(field => {
				return this.translateService.instant(field);
			});
			this.notify.error(
				this.translateService.instant('missing_fields_create_new', {
					fields: translatedFields.join(', '),
				})
			);
			return;
		}

		const createData = workOrderIntoCreateWorkOrder(data);

		this.workOrdersService
			.createWorkOrder(createData)
			.pipe(take(1))
			.subscribe(response => {
				const workOrder = response.workOrder;
				this.store$.dispatch(addWorkOrder({ workOrder }));
				// Create a simple action for updating the store and navigating to the new work order
				this.sidebarService.navigate({
					type: OpenSidebarEventTypes.OpenWorkOrder,
					route: RightSidebarRoutes.WorkOrder,
					pk: response.workOrder.pk,
				});
			});
	}
}
