import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	input,
	Output,
	signal,
} from '@angular/core';
import { AuCommentsComponent } from '@app/shared/ui-components';
import { EntityFormConfig } from '../../types';
import { CommentInputComponent } from '@app/shared/ui-components/au-comments/comment-input/comment-input.component';
import { CmmsCommentsService } from '@app/pages/cmms/services/cmms-comments.service';
import { ICMMSEntities } from '@app/pages/cmms/interfaces/cmms-global.interfaces';
import { IAuCommentEditEvent } from '@app/shared/ui-components/au-comments/i-au.comment';
import { AuErrorAdapter, AuUtilsFunctions } from '@app/shared';
import { IconSnackBarService } from '@app/core/services/icon-snack-bar.service';
import { AuDialogService } from '@app/core/services/au-dialog.service';
import { catchError } from 'rxjs';
import { TranslateService } from '@tolgee/ngx';

@Component({
	selector: 'activity-tab',
	imports: [AuCommentsComponent, CommentInputComponent],
	templateUrl: './activity-tab.component.html',
	styleUrl: './activity-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTabComponent {
	private readonly commentService = inject(CmmsCommentsService);
	private readonly notify = inject(IconSnackBarService);
	private readonly dialogService = inject(AuDialogService);
	private readonly translateService = inject(TranslateService);

	config = input<EntityFormConfig>();
	@Output() updateData = new EventEmitter<Partial<ICMMSEntities>>();

	requestees = signal(null);
	isRequest = signal(false);
	request = signal(null);

	editComment($event: IAuCommentEditEvent) {
		this.commentService.editEntityComment(
			$event.commentId,
			$event.commentText,
			this.config().data,
			(updatedEntityItem: ICMMSEntities) => {
				this.updateData.emit(updatedEntityItem);
			}
		);
	}

	submitComment(comment: {
		commentText: string;
		sendAsEmail: boolean;
		mentions: string[];
	}) {
		this.commentService
			.addCommentToEntity('workOrder', this.config().data.pk, comment)
			.pipe(
				catchError(error => {
					this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
					return error;
				})
			)
			.subscribe((addCommentResponse: { comment: string; success: boolean }) => {
				if (!addCommentResponse.success) {
					this.notify.error(this.translateService.instant('error_comment_save'));
				}

				const woCopy = AuUtilsFunctions.deepCopyObject(this.config().data);
				woCopy.comments.unshift(addCommentResponse.comment);

				this.updateData.emit(woCopy);
			});
	}

	deleteComment(commentId: string) {
		this.commentService
			.deleteComment(commentId)
			.pipe(
				catchError(error => {
					this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
					return error;
				})
			)
			.subscribe((deleteCommentResponse: { success: boolean }) => {
				if (!deleteCommentResponse.success) {
					this.notify.error(this.translateService.instant('error_comment_delete'));
				}

				const woCopy = AuUtilsFunctions.deepCopyObject(this.config().data);
				woCopy.comments = this.config().data.comments.filter(
					comment => comment.id !== commentId
				);

				this.updateData.emit(woCopy);
			});
	}

	deleteCommentConfirmation($event: { commentId: string }) {
		const dialog = this.dialogService.openAlertDialog({
			title: 'Delete comment',
			description: `Are you sure you want to delete your comment?</p>`,
			actionBtnText: 'DELETE',
		});

		this.dialogService.closedByEnter(dialog).subscribe(approved => {
			if (approved) {
				this.deleteComment($event.commentId);
			}
		});
	}
}
