import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { LogIn } from '@app/auth/state/auth.actions';
import { getAuthTranslate } from '@auth/state/auth.selectors';
import { Subject } from 'rxjs';
import { AuPasswordInput } from '@shared/au-password-input';
import {
	MatCard,
	MatCardHeader,
	MatCardTitle,
	MatCardContent,
} from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, AsyncPipe, TitleCasePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	imports: [
		MatCard,
		FlexModule,
		MatCardHeader,
		MatIcon,
		MatCardTitle,
		MatCardContent,
		ReactiveFormsModule,
		MatFormField,
		MatInput,
		MatError,
		NgIf,
		MatSuffix,
		MatButton,
		RouterLink,
		AsyncPipe,
		TitleCasePipe,
		NgxTolgeeModule,
	],
})
export class LoginComponent extends AuPasswordInput implements OnInit, OnDestroy {
	form: UntypedFormGroup;
	authTranslation$: any;
	passwordErrorValidation = 'validation_password_requirements';
	private destroy$ = new Subject<void>();

	constructor(
		private fb: UntypedFormBuilder,
		private store$: Store<IAppState>
	) {
		super();

		this.form = this.fb.group({
			email: ['', [Validators.required]],
			password: ['', [Validators.required]],
		});
	}

	ngOnInit() {
		this.authTranslation$ = this.store$.select(getAuthTranslate);
	}

	onSubmit() {
		if (this.form.valid) {
			const val = this.form.value;
			const payload = {
				email: val.email.toLowerCase(),
				password: val.password,
			};
			this.store$.dispatch(new LogIn(payload));
		}
	}

	get email() {
		return this.form.controls.email;
	}

	get password() {
		return this.form.controls.password;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
