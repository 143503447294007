import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
} from '@angular/core';
import { ICmmsAssignee } from '@app/pages/cmms/interfaces/assignee.interface';
import { NgxTolgeeModule, TranslateService } from '@tolgee/ngx';

@Component({
	selector: 'entity-created-by',
	imports: [NgxTolgeeModule],
	templateUrl: './entity-created-by.component.html',
	styleUrl: './entity-created-by.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCreatedByComponent {
	private readonly translateService = inject(TranslateService);
	private readonly datePipe = inject(DatePipe);
	createdBy = input<ICmmsAssignee>();
	created = input<string | Date>();

	createdByText = computed(() =>
		this.translateService.instant('created_by_name_and_date', {
			name: this.createdBy()?.firstName + ' ' + this.createdBy()?.lastName,
			date: this.datePipe.transform(this.created(), "d.M.y'"),
			time: this.datePipe.transform(this.created(), 'HH:mm'),
		})
	);
}
