import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	input,
	OnChanges,
	Output,
	signal,
} from '@angular/core';
import {
	ICMMSEntities,
	IMaintenanceData,
} from '@app/pages/cmms/interfaces/cmms-global.interfaces';
import { EntityFormConfig } from '../../types';
import { CommonModule } from '@angular/common';
import { CMMS_STATUSES } from '@app/pages/cmms/configs/statuses-by-entity';
import { EntityImageDisplayComponent } from '../../../../shared/entity-workspace-components/entity-image-display/entity-image-display.component';
import { EntityCategoryComponent } from '../../../../shared/entity-workspace-components/entity-category/entity-category.component';
import { EntityPriorityComponent } from '@app/shared/entity-workspace-components/entity-priority/entity-priority.component';
import { EntityDescriptionComponent } from '@app/shared/entity-workspace-components/entity-description/entity-description.component';
import { EntityLabelsSectionComponent } from '@app/shared/entity-workspace-components/entity-labels-section/entity-labels-section.component';
import { EntityDeadlineComponent } from '@app/shared/entity-workspace-components/entity-deadline/entity-deadline.component';
import { EntityScheduleComponent } from '@app/shared/entity-workspace-components/entity-schedule/entity-schedule.component';
import { EntityResponsibleComponent } from '@app/shared/entity-workspace-components/entity-responsible/entity-responsible.component';
import { EntityMaintenanceComponent } from '@app/shared/entity-workspace-components/entity-maintenance/entity-maintenance.component';
import { EntityCreatedByComponent } from '@app/shared/entity-workspace-components/entity-created-by/entity-created-by.component';

@Component({
	selector: 'information-tab',
	imports: [
		CommonModule,
		EntityImageDisplayComponent,
		EntityCategoryComponent,
		EntityPriorityComponent,
		EntityDescriptionComponent,
		EntityLabelsSectionComponent,
		EntityDeadlineComponent,
		EntityScheduleComponent,
		EntityResponsibleComponent,
		EntityMaintenanceComponent,
		EntityCreatedByComponent,
	],
	templateUrl: './information-tab.component.html',
	styleUrl: './information-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationTabComponent implements OnChanges {
	config = input<EntityFormConfig>();
	@Output() updateData = new EventEmitter<Partial<ICMMSEntities>>();

	deadline = signal<Date | null>(null);
	statuses = signal<string[] | null>(null);
	routineData = signal(null);
	maintenanceData = signal<IMaintenanceData>(null);

	ngOnChanges(): void {
		const config = this.config();
		if (config.entityType === 'WO') {
			this.deadline.set(config.data.deadline);
			this.statuses.set(CMMS_STATUSES.WORK_ORDERS);
			this.routineData.set(config.data.routineData);
			this.maintenanceData.set({
				deviationType: config.data.deviationType,
				costOfRepair: config.data.costOfWork,
				costAllocation: config.data.costAllocation,
				timeframe: config.data.timeframe,
			});
		}
	}

	updateDataFn(data: Partial<ICMMSEntities>) {
		this.updateData.emit(data);
	}
}
