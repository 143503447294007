import { Injectable } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

const localStorageKey = 'navbarSize';

@Injectable({ providedIn: 'root' })
export class NavbarSizeService {
	private newWidthSubject = new Subject<number>();

	constructor() {
		this.newWidthSubject.pipe(debounceTime(500)).subscribe(newWidth => {
			localStorage.setItem(localStorageKey, `${newWidth}`);
		});
	}
	getStoredWidth(): number | undefined {
		const storedValue = localStorage.getItem(localStorageKey);
		if (storedValue) {
			const parsed = parseInt(storedValue, 10);
			if (!isNaN(parsed)) {
				return parsed;
			}
		}
		return undefined;
	}

	setStoredWidth(newWidth: number) {
		this.newWidthSubject.next(newWidth);
	}
}
