import { inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuDialogService } from '@app/core/services/au-dialog.service';
import { Subject } from 'rxjs';
import { LocationsListComponent } from '../entity-workspace-components/locations-changed/locations-list.component';

@Injectable({
	providedIn: 'root',
})
export class LocationSelectorService {
	private readonly dialogService = inject(AuDialogService);

	locationChanged = new Subject<string[]>();
	openLocationDialog() {
		const dialog: MatDialogRef<LocationsListComponent, string[]> =
			this.dialogService.openComponentOverlayDialog(
				LocationsListComponent,
				'choose_location',
				'locationChanged'
			);

		dialog.afterClosed().subscribe((location: string[]) => {
			if (!location) {
				return;
			}
			this.locationChanged.next(location);
		});
	}
}
