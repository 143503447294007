import { Component, ChangeDetectionStrategy } from '@angular/core';
import { liveQuery } from 'dexie';
import { DatabaseService } from '@app/database/database.service';
import { MatButton } from '@angular/material/button';
import { AsyncPipe, JsonPipe } from '@angular/common';

@Component({
	selector: 'database-playground',
	templateUrl: './database-playground.component.html',
	styleUrls: ['./database-playground.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatButton, AsyncPipe, JsonPipe],
})
export class DatabasePlaygroundComponent {
	/** Here you have examples of livequeries for different variables in DB
	 * Feel free to uncomment necessary or add your own examples
	 * Link to documentation: https://dexie.org/docs/API-Reference#quick-reference
	 */
	// locations$ = liveQuery(() =>
	// 	this.dbService.buildings
	// 		?.where('id')
	// 		.equals('002e1484-c6e2-4b63-bf6a-9753cd1aa0e7')
	// 		.first()
	// );
	// organizations$ = liveQuery(() => this.dbService.organizations?.toArray());
	// roomTypes$ = liveQuery(() => this.dbService.roomTypes?.toArray());
	// organizationTypes$ = liveQuery(() =>
	// 	this.dbService.organizationTypes?.toArray()
	// );
	// teamTypes$ = liveQuery(() => {
	// 	return this.dbService.teamTypes.get('teamTypes');
	// });
	assignees$ = liveQuery(() => this.dbService.assignees?.toArray());

	constructor(private dbService: DatabaseService) {}

	addItems() {
		// this.dbService.addItem();
	}

	putItems() {
		// this.dbService.putItem();
	}

	clearDB() {
		this.dbService.resetDatabase();
	}
}
