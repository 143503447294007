import { OverlayModule } from '@angular/cdk/overlay';
import { Component, inject, OnInit } from '@angular/core';
import { NewRightSidebarService } from '../../services/new-right-sidebar/new-right-sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { RightSidebarOverlayComponent } from '../right-sidebar-overlay/right-sidebar-overlay.component';

@Component({
	selector: 'app-right-sidebar-container',
	templateUrl: './right-sidebar-container.component.html',
	styleUrls: ['./right-sidebar-container.component.scss'],
	imports: [OverlayModule, RightSidebarOverlayComponent, RouterOutlet],
})
export class RightSidebarContainerComponent implements OnInit {
	private readonly sidebar = inject(NewRightSidebarService);
	private readonly route = inject(Router);

	isOpen = toSignal(this.sidebar.isOpenState$);

	ngOnInit(): void {
		const urlActive = this.route.routerState.snapshot.root.children[0]?.children.find(
			value => value.outlet === 'right-sidebar'
		);
		if (urlActive) {
			this.sidebar.open();
		}
	}
}
