<form-field-label [labelTranslateKey]="'labels'"></form-field-label>
<div class="label-container outlined-icon-btn">
	@for (label of selectedLabels(); track label.id) {
		<label-badge [label]="label"></label-badge>
	}

	<button mat-icon-button [matMenuTriggerFor]="labelListMenu">
		<mat-icon svgIcon="add" />
	</button>
</div>

<mat-menu #labelListMenu xPosition="after" yPosition="below">
	<div
		class="label-list-menu nice-scroll colored-scroll-track visible-scroll"
		(click)="$event.stopPropagation()"
	>
		<label-list-menu
			[selectedLabelPks]="labelsForm.value"
			(selectedLabelsChange)="onSelectedLabelsChange($event)"
		/>
	</div>
</mat-menu>
