import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	input,
	OnChanges,
	OnDestroy,
	Output,
	signal,
	WritableSignal,
} from '@angular/core';
import { EntityFormConfig } from '../../types';
import { MatTabsModule } from '@angular/material/tabs';
import { InformationTabComponent } from '../information-tab/information-tab.component';
import { CommonModule } from '@angular/common';
import { ChecklistTabComponent } from '../checklist-tab/checklist-tab.component';
import { ActivityTabComponent } from '../activity-tab/activity-tab.component';
import {
	ICMMSEntities,
	ICMMSLocation,
} from '@app/pages/cmms/interfaces/cmms-global.interfaces';
import { MatButtonModule } from '@angular/material/button';
import { CMMS_STATUSES } from '@app/pages/cmms/configs/statuses-by-entity';
import { EntityStatusComponent } from '@app/shared/entity-workspace-components/entity-status/entity-status.component';
import { LocationsChipComponent } from '@app/shared/entity-workspace-components/locations-chip/locations-chip.component';
import { EntityWorkspaceActionsComponent } from '../../entity-workspace-actions/entity-workspace-actions.component';
import { EntityTitleComponent } from '@app/shared/entity-workspace-components/entity-title/entity-title.component';
import { NgxTolgeeModule } from '@tolgee/ngx';
@Component({
	selector: 'entity-workspace-form',
	imports: [
		CommonModule,
		MatTabsModule,
		MatButtonModule,
		InformationTabComponent,
		ChecklistTabComponent,
		ActivityTabComponent,
		EntityWorkspaceActionsComponent,
		EntityStatusComponent,
		LocationsChipComponent,
		EntityTitleComponent,
		NgxTolgeeModule,
	],
	templateUrl: './entity-workspace-form.component.html',
	styleUrls: ['./entity-workspace-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityWorkspaceFormComponent implements OnChanges, OnDestroy {
	initialConfig = input<EntityFormConfig>();
	newEntity = input(false);
	@Output() saveData = new EventEmitter<ICMMSEntities>();
	@Output() createNewEntityCallback = new EventEmitter<ICMMSEntities>();
	statuses: WritableSignal<string[]> = signal(CMMS_STATUSES.WORK_ORDERS);
	config = signal<EntityFormConfig>(this.initialConfig());
	locationAncestry: WritableSignal<ICMMSLocation[]> = signal([]);

	ngOnChanges(): void {
		this.config.set({ ...this.initialConfig() });
	}

	ngOnDestroy(): void {
		this.saveData.emit(this.config().data);
	}

	showChecklistTab(): boolean {
		return this.config().entityType === 'WO';
	}

	showActivityTab(): boolean {
		return this.config().entityType === 'WO';
	}

	// Input should be something like Partial<ICMMSEntities>, but compiler is not happy with that
	// Could be an indication that the design is not correct
	updateData(data: any) {
		this.config.update(value => ({ ...value, data: { ...value.data, ...data } }));
	}

	createNewEntity() {
		this.createNewEntityCallback.emit(this.config().data);
	}
}
