import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	HostListener,
	inject,
	OnDestroy,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NewRightSidebarService } from '../../services/new-right-sidebar/new-right-sidebar.service';
import {
	animate,
	state,
	style,
	transition,
	trigger,
	AnimationEvent,
} from '@angular/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'right-sidebar-overlay',
	imports: [MatIconModule],
	templateUrl: './right-sidebar-overlay.component.html',
	styleUrl: './right-sidebar-overlay.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('slideIn', [
			state('hidden', style({ transform: 'translateX(max(50vw,600px))' })),
			state('visible', style({ transform: 'translateX(0vw)' })),
			transition('visible <=> hidden', animate('300ms ease-in-out')),
		]),
	],
})
export class RightSidebarOverlayComponent implements AfterViewInit, OnDestroy {
	private readonly rightSidebarService = inject(NewRightSidebarService);

	@HostBinding('@slideIn') slideIn = 'hidden';
	@HostListener('@slideIn.done', ['$event']) done(event: AnimationEvent) {
		if (event.toState === 'hidden' && event.fromState !== 'void') {
			this.rightSidebarService.isOpenState$.next(false);
		}
	}

	constructor() {
		this.rightSidebarService.gracefulClose$.pipe(takeUntilDestroyed()).subscribe(() => {
			this.slideIn = 'hidden';
		});
	}

	ngOnDestroy(): void {
		this.rightSidebarService.close();
	}

	ngAfterViewInit(): void {
		this.slideIn = 'visible';
	}

	close() {
		this.rightSidebarService.close();
	}
}
