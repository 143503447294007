<div class="entity-workspace-container">
	@if (canMakeCopy()) {
		<button mat-icon-button (click)="copyEntity()">
			<mat-icon svgIcon="copy" />
		</button>
	}
	@if (shareable()) {
		<button mat-icon-button (click)="share()">
			<mat-icon svgIcon="share" />
		</button>
	}

	<options-button [config]="config()" />
</div>
