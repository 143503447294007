import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { isNavSidebarCollapsed } from '../state/layout.selectors';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { XmassLightsAnimationComponent } from '@layout/title/xmass-lights-animation/xmass-lights-animation.component';
import { XmassSnowComponent } from '@layout/title/xmass-snow/xmass-snow.component';
import { AuRoundAvatarModule } from '@shared/ui-components';
import { IUser } from '@shared/interfaces/user.interface';
import { getCurrentUser } from '@auth/state/auth.selectors';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AuUtilsFunctions } from '@app/shared';
import { LogOut } from '@auth/state/auth.actions';
import { DatabaseService } from '@app/database/database.service';
import { KeycloakService } from 'keycloak-angular';
import { MatButtonModule } from '@angular/material/button';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'app-title',
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.scss'],
	imports: [
		RouterLink,
		NgIf,
		XmassLightsAnimationComponent,
		XmassSnowComponent,
		AuRoundAvatarModule,
		AsyncPipe,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		NgxTolgeeModule,
	],
})
export class TitleComponent implements OnInit, OnDestroy {
	private readonly store$ = inject(Store);
	private readonly dbService = inject(DatabaseService);
	private readonly keycloakService = inject(KeycloakService);

	@Input() isMobile = true;

	isCollapsed = false;
	profile$: Observable<IUser> = this.store$.select(getCurrentUser);

	dateIsNearTo: string;
	easterEggCounter = 0;
	halloweenShow: boolean;
	xmassShow: boolean;
	xmassGif: string;

	private subscription: Subscription;
	private logoBaseUrl = './assets/icons/logo/';
	private xmassAudio: HTMLAudioElement;
	private gifTimeoutId: number | undefined;

	constructor() {
		this.checkIsDateNearToCelebration();
	}

	ngOnInit(): void {
		this.subscription = this.store$
			.select(isNavSidebarCollapsed)
			.subscribe(isCollapsed => (this.isCollapsed = isCollapsed));
	}

	onLogoClick() {
		this.easterEggCounter++;

		if (this.easterEggCounter === 5) {
			this.easterEggCounter = 0;

			if (this.dateIsNearTo === 'halloween') {
				this.startDoingHalloweenShow();
			}

			if (this.dateIsNearTo === 'xmass') {
				this.startDoingXmassShow();
			}
			// you can add more shows here
		}

		setTimeout(() => {
			if (this.easterEggCounter > 0) {
				this.easterEggCounter--;
			}
		}, 650);
	}

	onLogout() {
		this.dbService.resetDatabase();
		if (AuUtilsFunctions.isAutilityAuth()) {
			this.store$.dispatch(new LogOut());
		} else if (AuUtilsFunctions.isKeycloakAuth()) {
			this.keycloakService.logout();
		}
	}

	get logoImageUrl(): string {
		if (this.isMobile) {
			return `${this.logoBaseUrl}logo.svg`;
		}

		return !this.isCollapsed
			? `${this.logoBaseUrl}logo.svg`
			: `${this.logoBaseUrl}logo_mini.svg`;
	}

	private checkIsDateNearToCelebration() {
		const date = new Date();

		const month = date.getMonth() + 1;
		const day = date.getDate();

		if ((month === 10 && day > 22) || (month === 11 && day < 5)) {
			this.dateIsNearTo = 'halloween';
			this.logoBaseUrl = './assets/icons/logo/halloween/';
		} else if ((month === 12 && day >= 16) || (month === 1 && day < 5)) {
			this.dateIsNearTo = 'xmass';
			this.logoBaseUrl = './assets/icons/logo/xmass/';
		}
		// you can add more celebrations here
	}

	private startDoingHalloweenShow() {
		this.halloweenShow = true;

		new Audio('./assets/icons/logo/halloween/howl.m4a').play();
		setTimeout(() => (this.halloweenShow = false), 2000);
	}

	private startDoingXmassShow() {
		this.xmassShow = true;
		clearTimeout(this.gifTimeoutId);

		if (this.xmassAudio) {
			this.xmassAudio.pause();
			this.xmassAudio.currentTime = 0;
			this.xmassAudio.play();
		} else {
			const carolOfBellUrl = `${this.logoBaseUrl}xmass-show-audio.mp3`;
			this.xmassAudio = new Audio(carolOfBellUrl);
			this.xmassAudio.play();
		}

		const gifNumber = this.getRandomIntInclusive(1, 2);
		this.xmassGif = `${this.logoBaseUrl}/hipster_santa_${gifNumber}.gif`;

		this.gifTimeoutId = window.setTimeout(() => {
			this.xmassShow = false;
			this.xmassAudio.pause();
			this.xmassAudio.currentTime = 0;
		}, 27000);
	}

	private getRandomIntInclusive(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
