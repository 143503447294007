<form-field-label [labelTranslateKey]="label()"></form-field-label>

<mat-form-field class="maintenance-container" appearance="outline">
	<mat-select [formControl]="maintenanceForm" [placeholder]="'none_selected' | translate">
		<mat-option [value]="null">{{ 'none_selected' | translate }}</mat-option>
		@for (maintenanceOption of maintenanceOptions(); track maintenanceOption.name) {
			<mat-option [value]="maintenanceOption.name">{{
				maintenanceOption.description | translate
			}}</mat-option>
		}
	</mat-select>
</mat-form-field>
