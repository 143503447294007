<div class="chat-input-field-container">
	<textarea
		#messageInput
		class="ai-chat-message-input"
		rows="1"
		(input)="adjustRows(messageInput)"
		placeholder="{{ 'chatbot_preview_msg' | translate }}"
		[formControl]="formControl"
		(keydown)="trackHotKeys($event)"
	></textarea>
	<button
		*ngIf="isSpeechSupported"
		class="ai-chat-mic-button"
		(click)="toggleRecording()"
		[class.recording]="isRecording$ | async"
		[disabled]="isLoadingReply()"
	>
		<div
			*ngIf="isRecording$ | async"
			class="volume-circle"
			[style.transform]="'scale(' + (1 + (audioLevel$ | async) * 4) + ')'"
		></div>
		<ng-container *ngIf="!(isRecording$ | async)">
			<mat-icon class="mic-icon">mic</mat-icon>
		</ng-container>
	</button>
	<button
		class="ai-chat-send-button"
		(click)="submitRequestToAiChat()"
		[disabled]="isLoadingReply()"
	>
		<mat-icon svgIcon="envelop" class="send-icon" [class.active]="!isLoadingReply()" />
	</button>
</div>
