<p class="font-title-large">{{ 'maintenance' | translate }}</p>
<div class="maintenance-container">
	<div class="row">
		<maintenance-selector
			[maintenanceType]="'DeviationTypeChoices'"
			[selectedMaintenance]="currentEntity().deviationType"
			(maintenanceChanged)="maintenanceChanged.emit({ deviationType: $event })"
		></maintenance-selector>

		<entity-cost-form
			[selectedCost]="currentEntity().costOfWork"
			(costChanged)="maintenanceChanged.emit({ costOfWork: $event })"
		></entity-cost-form>
	</div>
	<div class="row">
		<maintenance-selector
			[maintenanceType]="'CostAllocationChoices'"
			[selectedMaintenance]="currentEntity().costAllocation"
			(maintenanceChanged)="maintenanceChanged.emit({ costAllocation: $event })"
		></maintenance-selector>

		<maintenance-selector
			[maintenanceType]="'TimeframeChoices'"
			[selectedMaintenance]="currentEntity().timeframe"
			(maintenanceChanged)="maintenanceChanged.emit({ timeframe: $event })"
		></maintenance-selector>
	</div>
</div>
