import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	inject,
	OnInit,
	signal,
	WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatListHeaderComponent } from '@app/ai-chat/chat-list/chat-list-header/chat-list-header.component';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'chat-list',
	imports: [
		CommonModule,
		ChatListHeaderComponent,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		NgxTolgeeModule,
	],
	templateUrl: './chat-list.component.html',
	styleUrls: ['./chat-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListComponent implements OnInit {
	private readonly aiChatService = inject(AiChatService);

	@HostBinding('style.width') hostWidth = '232px';

	chats = this.aiChatService.aiChats;
	selectedChatId: WritableSignal<number> = this.aiChatService.selectedChatId;
	isLoadingChats = signal(false);
	isCollapsed = signal(false);

	ngOnInit() {
		this.loadChats();
	}

	selectActiveChat(id: number): void {
		this.aiChatService.selectActiveChat(id);
	}

	async deleteChat(id: number, evt: MouseEvent): Promise<void> {
		evt.stopPropagation();
		await this.aiChatService.deleteChatAndMessages(id);
		this.loadChats();
	}

	collapseChatList(): void {
		this.isCollapsed.set(!this.isCollapsed());
		this.hostWidth = this.isCollapsed() ? '0' : '232px';
	}

	private loadChats(): void {
		this.isLoadingChats.set(true);
		this.aiChatService.loadChats().then(() => {
			if (!this.chats().length) {
				this.aiChatService.createNewChat();
			} else {
				this.aiChatService.selectActiveChat(this.chats()[0].id);
			}
			this.isLoadingChats.set(false);
		});
	}
}
