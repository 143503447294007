<mat-card appearance="outlined" fxFlex fxLayout="column" fxLayoutAlign="start center">
	<mat-card-header fxLayout="column">
		<mat-icon class="logo" svgIcon="logo" fxFlexOrder="0"></mat-icon>
		<mat-card-title fxFlexOrder="1"
			><h2>{{ (items$ | async)?.change_password.title | translate }}</h2></mat-card-title
		>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<mat-form-field
				hideRequiredMarker
				[class.password-field-error-gup]="
					passwordErrorCount > 2 && this.form.controls.password.touched
				"
			>
				<input
					#newPassword
					autocomplete="new_password"
					autofocus
					type="password"
					matInput
					placeholder="{{ (items$ | async)?.change_password.new_password | translate }}"
					formControlName="password"
				/>
				<mat-icon
					class="pointer"
					(click)="toggleInputType($event, newPassword)"
					matSuffix
					[svgIcon]="getInputIcon(newPassword.type)"
				></mat-icon>
				<mat-error>
					<span
						*ngIf="
							(this.form.controls.password.touched && checkError('length')) ||
							checkError('uppercase') ||
							checkError('lowercase') ||
							checkError('number')
						"
					>
						{{ 'validation_password_must_contain' | translate }}
					</span>
					<span class="error-info" *ngIf="checkError('length')">{{
						'validation_password_min_length' | translate
					}}</span>
					<span class="error-info" *ngIf="checkError('uppercase')">{{
						'validation_password_uppercase' | translate
					}}</span>
					<span class="error-info" *ngIf="checkError('lowercase')">{{
						'validation_password_lowercase' | translate
					}}</span>
					<span class="error-info" *ngIf="checkError('number')">{{
						'validation_password_number' | translate
					}}</span>
				</mat-error>
			</mat-form-field>

			<mat-form-field hideRequiredMarker>
				<input
					#passwordRepeat
					autocomplete="new_password_repeat"
					type="password"
					matInput
					placeholder="{{
						(items$ | async)?.change_password.repeat_password | translate
					}}"
					formControlName="confirmPassword"
					[errorStateMatcher]="errorMatcher"
				/>
				<mat-icon
					class="pointer"
					(click)="toggleInputType($event, passwordRepeat)"
					matSuffix
					[svgIcon]="getInputIcon(passwordRepeat.type)"
				></mat-icon>
				<mat-error>
					<span *ngIf="form.errors">{{
						'validation_passwords_mismatch' | translate
					}}</span>
				</mat-error>
			</mat-form-field>

			<button mat-raised-button color="primary" type="submit" mat-button>
				{{ (items$ | async)?.change_password.button | translate | titlecase }}
			</button>
		</form>
	</mat-card-content>
</mat-card>
