<mat-card appearance="outlined" fxFlex fxLayout="column" fxLayoutAlign="start center">
	<mat-card-header fxLayout="column">
		<mat-icon class="logo" svgIcon="logo"></mat-icon>
		<mat-card-title fxFlexOrder="1">
			<h2>
				{{ (authTranslation$ | async)?.recovery_password.title | translate | titlecase }}
			</h2>
		</mat-card-title>
		<mat-card-subtitle fxFlexOrder="2">{{
			(authTranslation$ | async)?.recovery_password.subtitle | translate
		}}</mat-card-subtitle>
	</mat-card-header>

	<mat-card-content>
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<mat-form-field hideRequiredMarker>
				<input
					autofocus
					type="email"
					matInput
					placeholder="{{ 'email_address' | translate }}"
					formControlName="email"
				/>
				<mat-error>
					<span *ngIf="email.hasError('required')">{{
						'validation_field_required' | translate
					}}</span>
					<span *ngIf="email.hasError('email') && email.touched">{{
						'email_invalid_message' | translate
					}}</span>
				</mat-error>
			</mat-form-field>

			<button
				[disabled]="emailSent$ | async"
				mat-raised-button
				color="primary"
				type="submit"
				mat-button
			>
				<span>{{
					(authTranslation$ | async)?.recovery_password.button | translate
				}}</span>
			</button>

			<a class="routerLink" routerLink="/login">
				<span
					>{{ (authTranslation$ | async)?.login.button | translate | titlecase }}
				</span>
			</a>
		</form>
	</mat-card-content>
</mat-card>
