<form-field-label [labelTranslateKey]="'priority'"></form-field-label>
<mat-form-field appearance="outline" class="priority-container">
	<mat-select
		[formControl]="priority"
		hideSingleSelectionIndicator="true"
		class="hidden-mat-select-arrow"
	>
		<mat-select-trigger class="selected-priority">
			{{ priority.value?.text | translate }}
			<mat-icon [ngClass]="priority.value.text" svgIcon="priority-circle"></mat-icon>
		</mat-select-trigger>

		@for (priority of defaultPriorities; track priority.level) {
			<mat-option [value]="priority" class="priority-option"
				>{{ priority.text | translate }}
				<mat-icon [ngClass]="priority.text" svgIcon="priority-circle"></mat-icon
			></mat-option>
		}
	</mat-select>
</mat-form-field>
