<div class="responsible-container">
	<form-field-label [labelTranslateKey]="responsibleType()"></form-field-label>

	<mat-form-field appearance="outline" (click)="openResponsibleDialog()">
		<input
			class="pointer"
			matInput
			[value]="
				selectedResponsible()?.firstName || selectedResponsible()?.lastName
					? selectedResponsible()?.firstName + ' ' + selectedResponsible()?.lastName
					: ('none' | translate)
			"
			readonly
		/>
		<mat-icon matPrefix [svgIcon]="'account-circle'"></mat-icon>
		<mat-icon matSuffix [svgIcon]="'down-dropdown-arrow'"></mat-icon>
	</mat-form-field>
</div>
